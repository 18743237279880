import React from "react";
import HeaderIcon from "./HeaderIcon";

const Header = () => {
  return (
    <header className="header">
      <HeaderIcon />
      <h1 className="wattcha">わっちゃ</h1>
    </header>
  );
};

export default Header;

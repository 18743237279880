import React from 'react';
import "./WaricanMemoryPicture.css"

const Card = ({ url }) => {
  return (
    <div className='waricanMemoryPicture'>
      {url !== null ? (
        <img src={url} alt="Image" style={{ width: "36vw", height: "35vw" }} />
      ) : (
        <p>写真</p>
      )}
    </div>
  );
};

export default Card;
import React from 'react';
import "./WaricanMemoryComment.css"

const WaricanMemoryComment = ({ comment }) => {
  return (
    <div className='waricanMemoryComment'>
        {comment === null ? "コメント" : comment}
    </div>
  );
};

export default WaricanMemoryComment;
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      members
      tempAmount
      realAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      members
      tempAmount
      realAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      members
      tempAmount
      realAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPaymentLog = /* GraphQL */ `
  mutation CreatePaymentLog(
    $input: CreatePaymentLogInput!
    $condition: ModelPaymentLogConditionInput
  ) {
    createPaymentLog(input: $input, condition: $condition) {
      id
      groupId
      name
      amount
      payer
      weights
      details
      location
      message
      s3Image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePaymentLog = /* GraphQL */ `
  mutation UpdatePaymentLog(
    $input: UpdatePaymentLogInput!
    $condition: ModelPaymentLogConditionInput
  ) {
    updatePaymentLog(input: $input, condition: $condition) {
      id
      groupId
      name
      amount
      payer
      weights
      details
      location
      message
      s3Image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePaymentLog = /* GraphQL */ `
  mutation DeletePaymentLog(
    $input: DeletePaymentLogInput!
    $condition: ModelPaymentLogConditionInput
  ) {
    deletePaymentLog(input: $input, condition: $condition) {
      id
      groupId
      name
      amount
      payer
      weights
      details
      location
      message
      s3Image
      createdAt
      updatedAt
      __typename
    }
  }
`;

import React from 'react';
import "./ReturnBottun.css"
import { useNavigate} from 'react-router-dom'; 

const defaultProps = {
  label: 'TOP画面に戻る',
};

const Button = (props) => {

    const navigate = useNavigate(); // 追加

    const handleButtonClick = () => {
        navigate('/'); // top画面に戻る
      };

  return (
    <button className='returnButton2' onClick={handleButtonClick}>
      {props.label ?? defaultProps.label}
    </button>
  );
};

export default Button;
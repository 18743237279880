import React from 'react';
import "./CreateBottun.css";

const defaultProps = {
  label: '作成',
};

const Button = ({command}) => {


  return (
    <button className='createButton'  onClick={command}>
      {defaultProps.label}
    </button>
        );
    };

export default Button;
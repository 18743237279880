import React from "react";
import './WattchaIntro.css';
import { useNavigate} from 'react-router-dom'; 
import IntroTitle from './Intro/IntroTitle1'
import IntroTitle2 from './Intro/IntroTitle2'
import Footer from "../Footer/Footer";
import PayIcon from "./Icon/PayIcon";
import ImageIcon from "./Icon/ImageIcon";
import NoteIcon from "./Icon/NoteIcon";
import RateIcon from "./Icon/%Icon";
import CreateButton from "../TopPage/CreateBottun/CreateBottun.jsx";
import HowToInfo from '../TopPage/Introhowto/HowToInfo/HowToInfo.jsx';
import InstaglamIcon from "../TopPage/Icon/InstaglamIcon"
import TikTokIcon from "../TopPage/Icon/TikTokIcon"
import XIcon from "../TopPage/Icon/X"
import SearchBottun from "../TopPage/SearchBottun/SearchBottun"
import InputSearch from "../TopPage/InputSearch/InputSearch"
import InputFilter from "../TopPage/InputFilter/InputFilter"

import { useEffect, useState } from 'react';
import { getGroupKeys } from '../cookieHelper';
import { getGroup } from './../graphql/queries';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

const WattchaIntro = () => {
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate(); // 追加

    const handleButtonClickCreate = () => {
        navigate('/'); // 追加
      };

    const handleButtonClickCreaterInfo = () => {
    navigate('/createrinfo'); // 追加
    };

    useEffect(() => {
        fetchGroupsFromCookie();
  } , []);

    const fetchGroupsFromCookie = async () => {
        try {
            const keys = getGroupKeys();
            const groups = await Promise.all(keys.map(async key => {
                const response = await client.graphql({ query: getGroup, variables: { id: key } });
                return response.data.getGroup;
            }));
            setGroups(groups);
        } catch (error) {
            console.error('Error fetching groups from cookie:', error);
        }
  };

  return (
    <div className="App bg_pattern backgroundE37D5B" style={{overflowY: 'auto'}}>
        <IntroTitle/>
    <div className="wattchaIntro background38b48b ">
        <div className="wattchaIntroState">
            <h2 className="wattchaTategaki warikanIntroFont">面倒な割り勘こそ、思い出の一部に。</h2>
            <h2>わっちゃ</h2>
            <h2 className="wattchaTategaki warikanIntroFont">わちゃわちゃな瞬間、　　　　　　　</h2>
        </div>
    </div>
    <IntroTitle2/>
    <div>
        <div className="pannding2vh backgroundColorf6f6f6 fukidashi-01-03 ">
            <h2 className="themeEngTop1">Question</h2>
            <h2 >飲み会や旅行での割り勘時に...</h2>
            <h2 className="warikanExplainFont textalalignstart pannding2vh backgroundRightStyle backgroundfff margintop5vh">結局、誰に何円払えばいい？</h2>
            <h2 className="warikanExplainFont textalalignend pannding2vh backgroundLeftStyle backgroundfff margintop5vh">今を楽しみたい（写真保存できないかな？）</h2>
            <h2 className="warikanExplainFont textalalignstart pannding2vh backgroundRightStyle backgroundfff margintop5vh">人によって払う金額を変えたい</h2>
            <h2 className="warikanExplainFont textalalignend pannding2vh backgroundLeftStyle backgroundfff margintop5vh">過去に誰と割り勘したっけ？</h2>
            <h2 className="margintop2vh">って思った事ない？</h2>
        </div>
    </div>
    <div className="fukidashi-01-04">
        <div className="margintop5vh">
            <h2 className="themeEngTop1">Answer</h2>
            <h2>わっちゃなら解決出来ます！</h2>
        </div>
        <div className="margintop5vh">
            <div className="backgroundWhite">
                <div className="border-top2"></div>
                <div className="border-top2 margin-top05vh"></div>
            </div>
            <div>
                <div className="IconState pannding2vh backgroundColorf6f6f6 border333333">
                    <PayIcon/>
                    <p className="Iconfont marginright2vh">自動で割り勘額を計算</p>
                </div>
            </div>
            <div className="IconState pannding2vh backgroundColorf6f6f6 border333333">
                <ImageIcon/>
                <p className="Iconfont marginright2vh">写真の保存</p>
            </div>
            <div className="IconState pannding2vh backgroundColorf6f6f6 border333333">
                <RateIcon/>
                <p className="Iconfont marginright2vh">支払い比率を設定</p>
            </div>
            <div className="IconState pannding2vh backgroundColorf6f6f6 border333333">
                <NoteIcon/>
                <p className="Iconfont marginright2vh">過去の割り勘を振り返り</p>
            </div>
        </div>
        <div className="backgroundWhite">
            <div className="border-top2"></div>
            <div className="border-top2 margin-top05vh"></div>
            <div className="border-top2 margin-top05vh"></div>
            <div className="border-top2 margin-top05vh"></div>
        </div>
        <h2 className="margintop2vh">でも、使い方は？</h2>
    </div>
    <div className='screenState margintop5vh'>
        <div className='topState'>
            <div className='bottomLine'>
                <div>
                    <h2 className='themeEngTop1'>How To Use</h2>
                    <h3 className='tukaikatafont'>つかいかた</h3>
                </div>
                <HowToInfo/>
                <div>
                    <div className='explainSNSMargin'>
                        <p className='tukaikataIntroMoji2'>🚨<br/>説明動画が<br/>👀 SNSで公開中 👀</p>
                        <div className='explainSNS'>
                            <div className="footerChildrenState marginright zigzag-border">
                                <InstaglamIcon/>
                                <XIcon/>
                                <TikTokIcon/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className='borderleftright background38b48b border333333 pannding2vh margintop-5vh'>
        <div>
            <h2 className='themeEngTop1 margintop5vh'>Let's Start!</h2>
            <h3 className='tukaikatafont pannding2vh'>わっちゃを始めよう!</h3>
            <h3 className='bottunosufont'>ボタンをポッチっと！</h3>
        </div>
        <div className='letsStartBottunState'>
            <CreateButton/>
        </div>
    </div>
    <div className="pannding5vh backgroundColorf6f6f6"></div>
        <div className='memoryBack backgroundF5F3EE borderleftright margintop-5vh border333333'>
            <h2 className='themeEngTop1 pannding2vh'>Memory</h2>
            <h2 className='tukaikatafont'>過去のグループ</h2>
            <h4 className='tukaikataIntroMoji'>前にグループを作成していたら、<br/>グループの履歴が表示✍️</h4>
            <div className='rirekiShow pannding2vh'>
                <ul className="pannding2vh margin0">
                    {groups.map(group => {
                        const totalAmount = group.tempAmount.reduce((acc, amount) => acc + amount, 0); // 合計値を計算

                        return (
                            <li key={group.id} >
                                <p className="rirekiDatefont borderleftright5px pannding05vh margin0 backgroundcolorWhite">{group.createdAt.substring(0, 10)}</p>
                                <div className="border333333 borderleftrighttop backgroundcolorWhite">
                                    <p className="rirekiTitlefont borderbottom pannding05vh margin0">
                                        <a href={`/Outlet/${group.id}`}>{group.name}</a>
                                    </p>
                                    <p className="rirekiAmountfont pannding05vh margin0">{totalAmount}円</p>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
        <Footer/>
    </div>
  );
};

export default WattchaIntro;

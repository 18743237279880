import React, { useContext, useState,useEffect } from "react";
import GroupNameContext from "../../../GroupCreatPage/GroupNameContext";
import { useNavigate, useParams } from 'react-router-dom';
import { createPaymentLog, updateGroup } from './../../../graphql/mutations';
import { getGroup } from '../../../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../../aws-exports';
import { post } from 'aws-amplify/api';
import GroupMemberContext from "../../../GroupCreatPage/GroupMemberContext";
import "./EditModal.css"

export const Modal = ({ onClose },props) => {
  const { groupMember, setGroupMember } = useContext(GroupMemberContext);
  const [errorMessage, setErrorMessage] = useState(''); // エラーメッセージ状態変数を追加
  const [newMemberName, setNewMemberName] = useState(groupMember);//id,name.ration
  const [newMemberNameonly, setNewMemberNameonly] = useState(null);//入力した名前を保持するためのもの追加
  const [members, setMembers] = useState(null);

  //backとの接続で追加
  const client = generateClient();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);

  function handleChange (event) {
    setNewMemberNameonly(event.target.value);
  };

  useEffect(() => {
    let isSubscribed = true;
    async function fetchGroup() {
        try {
            const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
            if (isSubscribed) {
                console.log('Fetched group:', response.data.getGroup);
                setGroup(response.data.getGroup);
            }
            console.log('Payments:')
        } catch (error) {
            console.error('Error fetching group:', error);
        }
    }
  
    fetchGroup();
    return () => {
        isSubscribed = false; // Cleanup function to prevent setting state on unmounted component
    };
  }, [groupId]);
  
  if (!group) {
    return <div>Loading...</div>; // Add a loading state
  }


  async function submitNewMemberName(index) {
    // Group を取得
    const groupResponse = await client.graphql({
      query: getGroup,
      variables: { id: groupId }
  });

    const updatedMembers = group.members;
    updatedMembers.splice(index, 1, newMemberNameonly);
    setMembers(updatedMembers);
  
    const updateGroupInput = {
        id: groupId,
        members: members,
    };

    // GroupMember名の更新
    await client.graphql({
        query: updateGroup,
        variables: { input: updateGroupInput }
    });

    window.location.reload();
  }

  
    return (
      <div className="container2">
        <div className="overlay" onClick={onClose} />
        <section className="containerInner">
          <h2 className="modalfont">グループメンバーの編集</h2>
          <button
            onClick={onClose}
            type="button"
            aria-label="閉じる"
            className="iconClose"
          >
            ×
          </button>
          <ul>
              {group.members.map((member,index) => (
                <p key={index}>
                  <input placeholder={member} onChange={handleChange} />
                  <div className="buttonContainer">
                    <button
                      onClick={() => {
                        submitNewMemberName();
                        onClose();
                      } }
                      type="button"
                      className="button closeButton"
                    >
                      変更
                    </button>
                    <button type="button" onClick={onClose} className="button nextButton">
                      キャンセル
                    </button>
                  </div>
                </p>
              ))}
          </ul>
          
          {/*ここにキー(index番号)に対しての処理を記載する？？
          <ul>
          {newMemberName.map((counter, i) => (
          <li key={i}>
          <input placeholder={groupMember[i].name} onChange={handleChange} />
          <p>{newMemberNameonly}</p>
          <div className="buttonContainer">
              <button
                onClick={() => {
                  submitNewMemberName(i);
                  onClose();
                } }
                type="button"
                className="button closeButton"
              >
                変更
              </button>
              <button type="button" onClick={onClose} className="button nextButton">
                キャンセル
              </button>
            </div>
          </li>
        ))}
          </ul>*/}
        </section>
      </div>
    );
  };

  /*
  {group && (
        <>
      <h2 className="memberTextEng1">Member</h2>
      <h2 className="memberTextJpa1">グループメンバー</h2>
        <ul onClick={onOpen} className="groupMemberClickStyle1">
        {group.members.map((member,index) => (
            <div key={index} className="groupMemberClickShow">
              {member}
            </div>
          ))}
  */


  /*{groupMember.map((item) => (
            <div key={item.id} className="groupMemberClickShow">
              {item.name}
              <br />
              {item.ration}
            </div> */

            /*
            <input placeholder={groupMember[0].name} onChange={handleChange}/>
          <p>{newMemberName.name}</p>
        
          <div className="buttonContainer">
            <button
              onClick={() => {
                submitNewMemberName();
                onClose();
                }}
              type="button"
              className="button closeButton"
            >
              変更
            </button>
            <button type="button" onClick={onClose} className="button nextButton">
              キャンセル
            </button>
          </div>
            */
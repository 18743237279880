import React, { useState,useEffect,useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../Header/Header';
import { getGroup, paymentLogByGroupId, getPaymentLog} from '../../graphql/queries';
import { updatePaymentLog, updateGroup } from './../../graphql/mutations';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../aws-exports';
import { post } from 'aws-amplify/api';
import ReturnBottun from "../ReturnBottun/ReturnBottun";
import ChangeBottun from "../ChangeBottun/ChangeBottun";
import ArrowObjects from "./ArrowObjects";
import WaricanEditContext from '../../GroupScreen/WaricanMemory/WaricanContext/WaricanEditContext';
import WaricanMemoryTitle from "../../GroupScreen/WaricanMemory/WaricanMemoryTitle/WaricanMemoryTitle";
import WaricanMemoryPicture from "../../GroupScreen/WaricanMemory/WaricanMemoryPicture/WaricanMemoryPicture";
import WaricanMemoryEditButton from "../../GroupScreen/WaricanMemory/WaricanMemoryEditButton/WaricanMemoryEditButton";
import WaricanMemoryLocation from '../../GroupScreen/WaricanMemory/WaricanMemoryEditLocation/WaricanMemoryLocation';
import WaricanMemoryComment from '../../GroupScreen/WaricanMemory/WaricanMemoryComment/WaricanMemoryComment';
import WaricanMemoryMember from '../../GroupScreen/WaricanMemory/WaricanMemoryMember/WaricanMemoryMember';
import WaricanMemoryMoney from '../../GroupScreen/WaricanMemory/WaricanMemoryMoney/WaricanMemoryMoney';
import WaricanMemoryCreateDate from "../../GroupScreen/WaricanMemory/WaricanMemoryCreateDate/WaricanMemoryCreateDate";
import "../../GroupScreen/WaricanMemory/WaricanMemory.css"
import "./EditObjects.css"
  

const EditObjects = ({ paylog, members }) => {
    //変更点を保存する
    //paymentlogの名前
    const [newPaymentLogName, setNewPaymentLogName] = useState("");
    //金額
    const [newPaymentLogAmount, setNewPaymentLogAmount] = useState("");
    //支払い者(比率をどうするかは要相談)
    const [newPaymentLogPayer, setNewPaymentLogPayer] = useState("");
    //コメント
    const [newPaymentLogMessage, setNewPaymentLogMessage] = useState("");
    //支払ってもらった人
    const [newPaymentLogPayered, setNewPaymentLogPayered] = useState("");

    const navigate = useNavigate();
    const {editedId, setEditedId} = useContext(WaricanEditContext);

    
    //会計名の編集
    const handleChangePaymentLogName = (event) => {
        setNewPaymentLogName(event.target.value);
        console.log("名前"+newPaymentLogName)
        if (setNewPaymentLogName === null) {
            setNewPaymentLogName(paylog.name)
        }
      };

    //金額の変更
    const handleChangePaymentLogAmount = (event) => {
    setNewPaymentLogAmount(event.target.value);
    console.log("名前"+newPaymentLogAmount)
    if (setNewPaymentLogAmount === null) {
        setNewPaymentLogAmount(paylog.amount)
    }
    };

    //支払い者の編集(ここのボタン選択に変える必要あり)
    const handleChangePaymentLogPayer = (event) => {
        setNewPaymentLogPayer(event.target.value);
        console.log("名前"+newPaymentLogName)
        if (setNewPaymentLogPayer === null) {
            setNewPaymentLogPayer(paylog.payer)
      };
    };

    //支払ってもらった人の編集
    const handleChangePaymentLogPayered = (event) => {
        setNewPaymentLogPayered(event.target.value);
        console.log("名前"+newPaymentLogName)
        if (setNewPaymentLogPayered === null) {
            setNewPaymentLogPayered(paylog.payer)
      };
    };

    //コメントの編集
    const handleChangePaymentLogMessage = (event) => {
        setNewPaymentLogMessage(event.target.value);
        console.log("名前"+newPaymentLogMessage)
        if (setNewPaymentLogMessage === null) {
            setNewPaymentLogMessage(paylog.message)
      };
    };


  /*useEffect(() => {

    async function getIdnetityPaymentId() {
        try {
            console.log("Get payment logs for groupId:", editedId);
            const response = await client.graphql({ query: getPaymentLog, variables: { id: editedId } });
            console.log('Get PaymentLog:', response.data.getPaymentLog);
            setPaylog(response.data.getPaymentLog);
            console.log('セットしたLogは', paylog);

        } catch (error) {
            console.error('Error of get payment log:', error);
        }
    }

    getIdnetityPaymentId()
  }, [editedId]);*/

    const submitEditinfo = async () =>{

        // Group を取得
        // const groupResponse = await client.graphql({
        //     query: getPaymentLog,
        //     variables: { id: editedId }
        // });
  
        // const updateGroupInput = {
        //     id: editedId,
        //     name: newPaymentLogName,
        //     amount: newPaymentLogAmount,
        //     //payer: newPaymentLogPayer,
        //     message: newPaymentLogMessage,
        // };

        // // Group の更新
        // await client.graphql({
        //     query: updatePaymentLog,
        //     variables: { input: updateGroupInput }
        // });
    
        // window.location.reload();
    };

  

  return (
    <div>
    {paylog && (
    <div className='paymentLogStyle backgroundColorf6f6f6 pannding2vh'>
        <div className='StateLogs'>
            <div className='waricanMemory'>
                <WaricanMemoryTitle name={paylog.name} />
                <WaricanMemoryPicture />
                <WaricanMemoryComment message={paylog.message} />
                <WaricanMemoryMember payer={paylog.payer} /*ここに払い先の情報も入れるようにする*/ />
                <WaricanMemoryMoney amount={paylog.amount} />
            </div>
        </div>
        <div>
            <div className='StateLogs'>
                <div className='paymentLogsState pannding2vh'>
                    <input placeholder="名前" onChange={handleChangePaymentLogName} className='paymentLogsStateInputForm' />
                </div>
                <div className='paymentLogsState2 pannding2vh'>
                    <input placeholder="金額" onChange={handleChangePaymentLogAmount} className='paymentLogsStateInputForm' />
                </div>
                <div className='paymentLogsState2 pannding2vh paymentLogMember'>
                    <label className='paymentLogMemberFont'>支払い者</label>
                    <select className="paymentLogMemberFont" onChange={handleChangePaymentLogPayer}>
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                    </select>
                </div>
                <div className='paymentLogsState2 pannding2vh paymentLogMember'>
                <label className='paymentLogMemberFont'>支払い先</label>
                    <select className="paymentLogMemberFont" onChange={handleChangePaymentLogPayered}>
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                    </select>
                </div>
                <div className='paymentLogsState2 pannding2vh'>
                    <input placeholder="写真" onChange={handleChangePaymentLogMessage} className='paymentLogsStateInputForm' />
                </div>
                <div className='paymentLogsState2 pannding2vh'>
                    <textarea placeholder="コメント" className='paymentLogsStateInputComment' />
                </div>
            </div>
        </div>
    </div>
  )}
    <ReturnBottun onClick={()=>navigate(`/Outlet/${paylog.groupId}`)}/>
    <ChangeBottun onClick={submitEditinfo}/>
  </div>
  )};

export default EditObjects;

import React from 'react';

const styles = {
  Icon: {
    color: '#000000',
    fill: '#000000',
    fontSize: '3rem',
    width: '3rem',
    height: '3rem',
    margin: '2vh',
  },
};

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#fa4a00">
    <path d="M542.63-425.5q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM228.37-280q-28.33 0-48.35-20.02Q160-320.04 160-348.37v-394.26q0-28.2 20.02-48.29Q200.04-811 228.37-811h629q28.1 0 48.12 20.08 20.01 20.09 20.01 48.29v394.26q0 28.33-20.01 48.35Q885.47-280 857.37-280h-629Zm97.13-65.5H760q0-42 29-71t71-29v-200q-42 0-71-29.12t-29-70.88H325.5q0 41.76-29 70.88-29 29.12-71 29.12v200q42 0 71 29t29 71Zm474.5 191H102.63q-28.1 0-48.12-20.01-20.01-20.02-20.01-48.12V-680h68.13v457.37H800v68.13Zm-574.5-191v-400 400Z"/>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const Icon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent /> 
      : <defaultProps.IconComponent />
  );
};

export default Icon;
import "./styles.css";
import React, { useState } from 'react';
import GroupMemberContext from './GroupCreatPage/GroupMemberContext';
import GroupNameContext from "./GroupCreatPage/GroupNameContext";
import SceneIcon from "./GroupCreatPage/SceneIcon";
import GaikaContext from "./GroupCreatPage/GaikaContext";
//import PayNameContext from "./InputAccount/Context/PayNameContext.js";
//import PayerNameContext from "./InputAccount/Context/PayerNameContext.js";
//import AmountContext from "./InputAccount/Context/AmountInputContext.js";
//import MessageContext from "./InputAccount/Context/MessageContext.js";
//import ImageContext from "./InputAccount/Context/ImageContext.js";
//import LocationContext from "./InputAccount/Context/LocationContext.js";  // delete by baison
import { BrowserRouter} from 'react-router-dom';//追加
import route from './route'
import Header from "./Header/Header";

const App = () => {
  const [groupMember, setGroupMember] = useState([]);
  const [index, setIndex] = useState(0);
  const [groupName , setGroupName] = useState("");
  const [selectedButton , setSelectedButton ] = useState("")
  const [gaika, setGaika ] = useState("")
  const [payName, setPayName ] = useState("")
  const [payer, setPayer ] = useState("")
  const [amount, setAmount ] = useState("")
  const [message, setMessage ] = useState("")
  const [image, setImage ] = useState("")
  const [Latitude,setlatitude] = useState("")
  const [Longitude,setlongitude] = useState("")
  

  return ( // basion version
    <>
    <div className="App" style={{overflowY: 'auto'}}>
    <BrowserRouter>
      <GroupMemberContext.Provider value={{ groupMember, index, setGroupMember, setIndex }}>
      <GroupNameContext.Provider value = {{ groupName , setGroupName}}>
      <SceneIcon.Provider value={{selectedButton , setSelectedButton}}>
      <GaikaContext.Provider value={{gaika, setGaika}}>
        {route}
      </GaikaContext.Provider>
      </SceneIcon.Provider>
      </GroupNameContext.Provider>
      </GroupMemberContext.Provider>
    </BrowserRouter>
    </div>
    </>
  );
  
  /*return ( // akira version
    <>
    <div className="App" style={{overflowY: 'auto'}}>
    <BrowserRouter>
      <GroupMemberContext.Provider value={{ groupMember, index, setGroupMember, setIndex }}>
      <GroupNameContext.Provider value = {{ groupName , setGroupName}}>
      <SceneIcon.Provider value={{selectedButton , setSelectedButton}}>
      <GaikaContext.Provider value={{gaika, setGaika}}>
      <PayNameContext.Provider value = {{ payName ,setPayName}}>
      <PayerNameContext.Provider value = {{ payer ,setPayer}}>
      <AmountContext.Provider value = {{ amount ,setAmount}}>
      <MessageContext.Provider value={{message,setMessage}}>
      <ImageContext.Provider value={{image,setImage}}>
      <LocationContext.Provider value={{Latitude,Longitude,setlatitude,setlongitude}}>
        {route}
      </LocationContext.Provider>
      </ImageContext.Provider>
      </MessageContext.Provider>
      </AmountContext.Provider>
      </PayerNameContext.Provider>
      </PayNameContext.Provider>
      </GaikaContext.Provider>
      </SceneIcon.Provider>
      </GroupNameContext.Provider>
      </GroupMemberContext.Provider>
    </BrowserRouter>
    </div>
    </>
  );
  */
};

export default App;
//HEader箇所のpopupをモーダルウィンドウにする
import React, { useEffect, useState } from 'react';
import "./InputRation.css"

const InputField = ({ index, value, command }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;

    // 入力が空のときは仮の値として1を設定
    if (newValue === "") {
      setInputValue("");
      command(index, 1);
    } else {
      // 数値のみ許可
      const parsedValue = parseFloat(newValue);
      if (!isNaN(parsedValue)) {
        setInputValue(newValue);
        command(index, parsedValue);
      } else {
        // 無効な入力の場合は前の値を保持
        setInputValue(value);
      }
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="input-ration-container">
      <span className="input-label">比率</span>
      <input className='inputRation' 
        value={inputValue}
        onChange={handleChange} 
        onClick={handleClick} />
    </div>
  );
};

export default InputField;

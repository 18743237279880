import React from 'react';
import "./WaricanMemoryMember.css"

const WaricanMemoryMemberTo = ({bePaidMembers}) => {

  if (!bePaidMembers || bePaidMembers.length === 0) {
    return
  }

  return (
    <div>
      {bePaidMembers.map((member, index) => (
        <span key={index} className='waricanMemoryMemberTo'>
          {member[0]}
        </span>
      ))}
    </div>
  );
};

export default WaricanMemoryMemberTo;
import "../styles.css";
import "./GroupPage.css"
import "./groupMember.css"
import { useParams, useNavigate } from 'react-router-dom';
import { getGroup, paymentLogByGroupId } from '../graphql/queries';
import { getUrl } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../aws-exports';
import { post } from 'aws-amplify/api';
import React, { useEffect,useContext, useState } from "react";
import Header from "../Header/Header"
import GroupMemberContext from '../GroupCreatPage/GroupMemberContext';
import GroupNameContext from "../GroupCreatPage/GroupNameContext";
import SceneIcon from "../GroupCreatPage/SceneIcon";
import GaikaContext from "../GroupCreatPage/GaikaContext";
import WaricanMemoryCreate from "./WaricanMemoryCreate"
import Box from "./Box";
import URLButton from "./URLButton";
import ReturnBottun from "./ReturnBottun/ReturnBottun"
import EditButton from "./EditBottun/EditBottun";
import WaricanMemory from "./WaricanMemory/WaricanMemory"
import Footer from "../Footer/Footer";
import { addGroupKey } from '../cookieHelper'; 
import CustomBarChart from "../customBarChart";

Amplify.configure(awsconfig);
const client = generateClient();

// メイン関数
function Top(props) {
  const { groupName } = useContext(GroupNameContext);
  const { groupMember } = useContext(GroupMemberContext);
  const {selectedButton} = useContext(SceneIcon);
  const {gaika} = useContext(GaikaContext);
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [paymentLogs, setPaymentLogs] = useState([]);
  const [liquidation, setLiquidation] = useState([]);
  const [remains, setRemains] = useState([]);

  const [imageUrls, setImageUrls] = useState([]);

  const [showDetails, setShowDetails] = useState(false);

  //page遷移時にグループ情報取得後に実行
  useEffect(() => {
    async function fetchGroup() {
      try {
        const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
        console.log('Fetched group:', response.data.getGroup);
        setGroup(response.data.getGroup);

        // tempAmountの合計値を計算
        const tempAmountSum = response.data.getGroup.tempAmount.reduce((sum, value) => sum + value, 0);

        // tempAmountの合計値が0の場合は、liquidationの処理をスキップ
        if (tempAmountSum !== 0) {
          // tempAmountとrealAmountの差分リストを作成
          const paymentDiff = response.data.getGroup.tempAmount.map((temp, index) => temp - response.data.getGroup.realAmount[index]);
          console.log('Payment Diff:', paymentDiff);

          const restOperation = await post({
            apiName: 'wattchaRestApi',
            path: '/items',
            options: {
              body: { 
                numbers: paymentDiff
              }
            }
          });

          const { body } = await restOperation.response;
          const restResponse = await body.json();

          console.log('API Response:', restResponse);

          setLiquidation(restResponse.liquidation);
          setRemains(restResponse.remains);
        }
      } catch (error) {
        console.error('Error fetching group or liquidation:', error);
        const dammyGroup = {
          id: 'noApiResponse-it-is-test-id',
          name: 'No API Response',
          members: ['ひとし', 'ゆきな', 'ながさわ'],
          tempAmount: [0, 0, 0],
          realAmount: [0, 0, 0]
        }
        setGroup(dammyGroup)
      }
    }

    async function fetchPaymentLogs() {
      try {
        console.log("Fetching payment logs for groupId:", groupId);
        const response = await client.graphql({
            query: paymentLogByGroupId,
            variables: { groupId }
        });
        console.log('GraphQL response:', response); // [テスト用]レスポンス全体をログに出力
        
        if (response.data && response.data.paymentLogByGroupId) {
          const tempPaymentLogs = response.data.paymentLogByGroupId.items;
          
          // createdAtで昇順に並び替え
          tempPaymentLogs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          setPaymentLogs(tempPaymentLogs);
        
        } else {
          // レスポンスが空の場合
          console.error('No payment logs found:', response);
        }
      
      } catch (error) {
        console.error('Error fetching payment logs:', error);
      }
    }

    fetchGroup();
    fetchPaymentLogs();
    addGroupKey(groupId);
  }, [groupId]);

  useEffect(() => {
      async function fetchImageUrls() {
          if (paymentLogs.length === 0) return;

          try {
              console.log('Fetching image URLs for payment logs:', paymentLogs);
              const imageNames = paymentLogs.map(log => log.s3Image);
              console.log('existing image paths:', imageNames);
              const urls = await getImageUrls(imageNames);
              console.log('Fetched image URLs:', urls);
              setImageUrls(urls);
          } catch (error) {
              console.error('Error fetching image URLs:', error);
          }
      }

      fetchImageUrls();
  }, [paymentLogs]);

  const getImageUrls = async (imageNames) => {
      
      const urls = await Promise.all(imageNames.map(async (imageName) => {
          if (imageName !== null) {
              try {
                  const result = await getUrl({
                      path: imageName,
                      options: {
                          expiresIn: 3600,
                          validateObjectExistence: true
                      }
                  });
                  return result.url;
              } catch (error) {
                  console.error('Error getting image URLs:', error);
                  return "error";
              }
          } else {
              console.log('this image path is null.');
              return null;
          }
      }));
      return urls;
  };

  if (!group) {
      return <div>Loading...</div>; // Add a loading state
  }

  //グループ名を押下すると、グループメンバーが表示されるようにする
  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  //svgの記載
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#5000ca" fill-opacity="1" d="M0,128L205.7,192L411.4,96L617.1,32L822.9,192L1028.6,224L1234.3,64L1440,32L1440,0L1234.3,0L1028.6,0L822.9,0L617.1,0L411.4,0L205.7,0L0,0Z"></path>
  </svg>

  const DetailsComponent = () => {
    return (
      <div className="groupMemberClickStyle">
      <ul className="groupMemberClickStyle">
        {group.members.map((member,index) => (
          <li key={member} className="groupMemberClickShow">{member}</li>
        ))}
      </ul>
      <EditButton onClick={()=>navigate(`/Outlet/${groupId}/GroupMemberEdit`)}/>
      </div>
    );
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        alert('URLがクリップボードにコピーされました');
      })
      .catch(err => {
        console.error('クリップボードにコピーできませんでした: ', err);
      });
  };

  const transferUrlToLine = () => {
    const lineUrl = `https://line.me/R/msg/text/?${encodeURIComponent(`わっちゃで割り勘！「${group.name}」に支払い内容と思い出を記録しよう！！\n\n`)}${encodeURIComponent(window.location.href)}`;
    window.open(lineUrl, '_blank');
  };

  //画面遷移後、画面の一番初めに行くように調整なぜかbg_patternをclassで定義するといけた→原因究明必要あり
  return (
    <div className="App Paper_v2" style={{overflowY: 'auto'}}>
    <Header/>
    {group && (
    <div>
        <div className="screenState">
          <p className="groupPageEng">GroupPage</p>
          <p className="groupPageJpn">グループページ</p>
          <div onClick={handleClick} >
            <div className="speechBubble">
              <div>
                <p className="sceneName1">{selectedButton} {group.name} {group.members.length}人</p>
             </div>
            {showDetails && <DetailsComponent/>}
            </div>
            </div>
          </div>
    </div>
    )}

    {paymentLogs && (
      <><div className="warikanbackground background-bottom2vh">
          <div>
            <div className="border-top3 backgroundColorWhite margin-top2vh"></div>
            <div className="tatesenStateYoko backgroundColorcf9f9f9">
              <div className="tatesenState margin-left2vh">
                <div className="tatesen "></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
              <div className="tatesenState">
                <div className="tatesen"></div>
                <div className="blackbox margin-top2vh"></div>
              </div>
            </div>
          </div>
          <div>
            <div className="pandding2vh">
              <h2 className="themeEngTop1">WaRican!!</h2>
              <h2 className="setumeifont">会計シェアの記録</h2>
            </div>
            <WaricanMemoryCreate onClick={()=>navigate(`/Outlet/${groupId}/InputAccount`)}/>
            <WaricanMemory paymentLogs={paymentLogs} imageUrls={imageUrls} members={group.members}/>
          </div>
        </div>
        <div className="backgroundColorWhite">
            <div className="border-top3 backgroundColorWhite"></div>
            <div className="border-top3 margin-top05vh panddingright2vh slant-line-05"></div>
            <div className="border-top3 margin-top05vh panddingright2vh slant-line05"></div>
            <div className="border-top3 margin-top05vh panddingright2vh slant-line-05"></div>
        </div>
      </>
    )}

      <div className="backgroundColorececea panddingop2vh">
        <h2 className="themeEngTop1">How Much??</h2>
        <h2 className="setumeifont">精算方法</h2>
        <Box liquidation={liquidation} members={group.members} remains={remains} />
        <CustomBarChart members={group.members} tempAmount={group.tempAmount} realAmount={group.realAmount} />
      </div>
      <div className="backgroundColorcf9f9f9 Paper_v2 background-bottom2vh">
      	<h2 className="themeEngTop1 panddingop2vh">Share</h2>
        <h2 className="setumeifont">わっちゃ共有</h2>
        <URLButton 
          urlCommand={copyUrlToClipboard}
          lineCommand={transferUrlToLine}
        />
        <ReturnBottun/>
      </div>
        <Footer/>
      </div>
  );
}

export default Top;

import React from "react";
import "./WaricanMemoryCreate.css"
import { useNavigate} from 'react-router-dom'; // 追加


const IconComponent = (props) => (
    <svg className="waricanCreateIcon"  viewBox="0 0 448 512">
      <path d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
      </path>
    </svg>
  );
  
  const defaultProps = {
    IconComponent,
  };

const WaricanMemoryCreate = (props) => {
    const navigate = useNavigate(); // 追加

    const handleButtonClick = (props) => {
        navigate(`/Outlet/${props.groupId}/InputAccount`); // 追加
      };
return (
    <>
       <div href="#" className="btnarrow4">記録の登録は右のボタンから！</div>
       <button className="waricanCreateButton" onClick={props.onClick}>
        {
          props.IconComponent 
            ? <props.IconComponent className="waricanCreateIcon" /> 
            : <defaultProps.IconComponent />
        }
      </button>
    </>
  );
};
export default WaricanMemoryCreate;

import React from 'react';
import "./WaricanMemoryMember.css"

const IconComponent = () => (
  <svg className='waricanMemoryMemberIcon'  viewBox="0 0 24 24">
    <g fill="none">
    <path d="M0 0h24v24H0z">
    </path>
    <path d="M0 0h24v24H0z">
    </path>
    <path d="M0 0h24v24H0z">
    </path>
    </g>
    <path opacity=".3" d="M15 9.86v4.28L18.03 12zm-9 0v4.28L9.03 12z">
    </path>
    <path d="m4 18 8.5-6L4 6v12zm2-8.14L9.03 12 6 14.14V9.86zM21.5 12 13 6v12l8.5-6zM15 9.86 18.03 12 15 14.14V9.86z">
    </path>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const WaricanMemoryMemberIcon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent className='waricanMemoryMemberIcon' /> 
      : <defaultProps.IconComponent />
  );
};

export default WaricanMemoryMemberIcon;
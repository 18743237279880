import React from "react";
import './Footer.css'
import InstagramIcon from './Icon/InstaglamIcon'
import XIcon from './Icon/X'
import TikTokIcon from './Icon/TikTokIcon'
import { useNavigate} from 'react-router-dom'; 

const Footer = () => {
    const navigate = useNavigate(); // 追加

    const handleButtonClickCreate = () => {
        navigate('/createGroup'); // 追加
      };

    const handleButtonClickCreaterInfo = () => {
    navigate('/createrinfo'); // 追加
    };

    const handleButtonClickCreaterWattchaIntro = () => {
    navigate('/'); // 追加
    };


  return (
    <footer className="footer">
      <h1 className="fontcolor">わっちゃ</h1>
      <div className="footerChildrenState marginright">
        <InstagramIcon/>
        <XIcon/>
        <TikTokIcon/>
      </div>
      <div className="footerChildrenState">
        <p className="margin2vh fontcolor" onClick={handleButtonClickCreaterWattchaIntro}>わっちゃとは？</p>
        <p className="margin2vh fontcolor" onClick={handleButtonClickCreate}>わっちゃを始める!</p>
      </div>
      <div className="footerChildrenState">
        <p className="margin2vh fontcolor fontsize07">よくある質問</p>
        <a className="fontcolor fontsize07" href="https://docs.google.com/forms/d/1zt4vh-AmXu7gsf4DYCuOPoaf3_HdHhzVbUrxVcPzdMY/edit">お問い合わせフォーム</a>
        <p className="margin2vh fontcolor fontsize07" onClick={handleButtonClickCreaterInfo}>運営元情報</p>
      </div>
      <div className="border-top">
        <p className="fontcolor">© 2024 わっちゃ</p>
    </div>
    </footer>
  );
};

export default Footer;

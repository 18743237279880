import React from 'react';
import "../styles.css"
import "./TopPage.css"
import BubbleAnimation from "./BubbleAnimation/BubbleAnimation"
import CreateButton from "./CreateBottun/CreateBottun"
import IntroTitle from "./Intro/IntroTitle"
import IntroHowToTitle from './Introhowto/IntroHowToTitle';
import IntroHowTo from './Introhowto/IntroHowTo';
import ArrowBox from './ArrowBox/ArrowBox'
import SearchBottun from "./SearchBottun/SearchBottun"
import InputSearch from "./InputSearch/InputSearch"
import InputFilter from "./InputFilter/InputFilter"
import Footer from '../Footer/Footer'

import { getGroup } from './../graphql/queries';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getGroupKeys } from '../cookieHelper';

const client = generateClient();

const Top = () => {

  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    fetchGroupsFromCookie();
  }, []);

  const fetchGroupsFromCookie = async () => {
    try {
      const keys = getGroupKeys();
      const groups = await Promise.all(keys.map(async key => {
        const response = await client.graphql({ query: getGroup, variables: { id: key } });
        return response.data.getGroup;
      }));
      setGroups(groups);
    } catch (error) {
      console.error('Error fetching groups from cookie:', error);
    }
  };

  return(
    <div className="App bg_pattern Paper_v2" style={{overflowY: 'auto'}}>
      <div className='topState'>
        <div>
          <BubbleAnimation/>
        </div>
        <div className='bottomLine'>
          <IntroTitle/>
          <h2 className='themeEngTop1'>Start!</h2>
          <h2 >わっちゃで思い出と割り勘を記録しよう！！</h2>
          <div>
            <h2>でも、使い方知らなくね？？</h2>
            <h1 className='tukaikata'>つかいかた</h1>
          </div>
          <div className='introHowTo'>
            <IntroHowTo/>
          </div>
          <ArrowBox/>
          <h2 className='h2margin'>下の作成ボタンからわっちゃを始めよう〜</h2>
          <div className='startBottun'>
            <a href="#" class="btnlinestretches5"><span>ボタンをタップ！</span></a> 
            <CreateButton/>
          </div>
        </div>
        <div className='memoryBack'>
          <h2 className='themeEngTop2'>Memory</h2>
          <h2>過去のグループ</h2>
          <h4>グループ参加から6か月まで履歴に残ります。</h4>
          <div className='memoryBottunState'>
            <SearchBottun/>
            <InputSearch/>
            <InputFilter/>
          </div>
          <ul>
            {groups.map(group => (
              <li key={group.id}>
                <a href={`/Outlet/${group.id}`}>{group.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Top;
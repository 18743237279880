/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      members
      tempAmount
      realAmount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members
        tempAmount
        realAmount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentLog = /* GraphQL */ `
  query GetPaymentLog($id: ID!) {
    getPaymentLog(id: $id) {
      id
      groupId
      name
      amount
      payer
      weights
      details
      location
      message
      s3Image
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentLogs = /* GraphQL */ `
  query ListPaymentLogs(
    $filter: ModelPaymentLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        name
        amount
        payer
        weights
        details
        location
        message
        s3Image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentLogByGroupId = /* GraphQL */ `
  query PaymentLogByGroupId(
    $groupId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentLogByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        name
        amount
        payer
        weights
        details
        location
        message
        s3Image
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

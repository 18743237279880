import "./InputForm.css"

const defaultProps = {
    text: '支払い名'
  };

const InputForm = ({command}) => {

    return(
        <div>
            <form>
                <input 
                    className="paymentName" 
                    placeholder={defaultProps.text}
                    onChange={command}/>
            </form>
        </div>
    );
}

export default InputForm;

import {useContext} from 'react';
import GroupNameContext from './GroupNameContext';
import "./CreateGroupBottun.css"


const defaultProps = {
  text: ''
};

const GroupNameIuput = (props) => {
    const { groupName , setGroupName } = useContext(GroupNameContext);//追加


  const handleChange = (event) => {
    setGroupName(event.target.value);
    console.log('groupName:', groupName); // コンソールログに出力
  };

  return (
    <input
      className='groupNameInput'
      placeholder={defaultProps.text}
      value={groupName}
      onChange={handleChange}
    />
  );
};

export default GroupNameIuput;
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://uffxio46zg.execute-api.ap-northeast-1.amazonaws.com/main",
            "region": "ap-northeast-1"
        },
        {
            "name": "wattchaRestApi",
            "endpoint": "https://wyk9roy8f8.execute-api.ap-northeast-1.amazonaws.com/main",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fsq4ntwocfh47dfu3574bc77ny.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ap-northeast-1:fedeca93-c21d-4d64-8cc3-6c5599c91740",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_4WfMHgU5T",
    "aws_user_pools_web_client_id": "5112kagv7dgp37cpoa18jgcakg",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "wattcha69d37308f44c47aa967e17dccf32a9b4134938-main",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;

import React from 'react';
import RationEven from "./RationEven"
import RationSetInput from "./RationSetInput"
import "./RationSet.css"


const RationSetBottun = ({splitMethod, commandEvenly, commandWeighted}) => {

  return (
    <div className='rationSetButtonState'>
      <RationEven command={commandEvenly} isSelected={splitMethod === 'evenly'}/>
      <RationSetInput command={commandWeighted} isSelected={splitMethod === 'weighted'}/>
   </div>
  );
};

export default RationSetBottun;
import React from "react";
import "./CreaterInfo.css"
import { useNavigate} from 'react-router-dom'; 
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const CreaterInfo = () => {
    const navigate = useNavigate(); // 追加

    const handleButtonClick = () => {
        navigate('/'); // 追加
      };

  return (
    <div className="App bg_pattern" style={{overflowY: 'auto'}}>
        <Header/>
            <div className="CreateInfo screenState">
                <div className="width100">
                    <h1 className="titleFont green">運営元情報</h1>
                    <p className="subFont">わっちゃは、大学の同期3人で作成した、</p>
                    <p className="subFont">2024年から運営している割り勘アプリケーションです</p>
                </div>
                <div className="width100">
                    <h2 className="titleFont orange">メンバー情報</h2>
                    <p className="subFont">大学時代、1年間北海道で同じ寮で過ごす</p>
                    <p className="subFont">新卒でコンサルタント、組み込みエンジニアとして入社</p>
                </div>
                <div className="width100">
                    <h2  className="titleFont aquaBule">お問い合わせ</h2>
                    <p className="subFont">お仕事のご依頼、広告掲載、運営に関するご質問がございましたら、以下のメールまでお問い合わせください</p>
                    <p className="subFont2 margin3vh ">wattcha.31b@gmail.com</p>
                    <p className="subFont">こんな機能欲しい！ここ直したら？などのご意見がございましたら、以下のフォームよりお問い合わせください</p>
                </div>
                <div className="subFont2 margin3vh">
                <a className="formmargin black" href="https://docs.google.com/forms/d/1zt4vh-AmXu7gsf4DYCuOPoaf3_HdHhzVbUrxVcPzdMY/edit">お問い合わせフォーム</a>
                </div>
            </div>
        <Footer/>
    </div>
  );
};

export default CreaterInfo;

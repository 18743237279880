import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import GroupNameContext from '../../GroupCreatPage/GroupNameContext';
import "./GroupTitleEdit.css"
import { Modal } from "../EditModal/GroupTitleEdit/GroupNameEditModal";
import { useModal } from "../EditModal/GroupTitleEdit/useModal";
import { ModalPortal } from "../EditModal/GroupTitleEdit/reactPortal";
import { getGroup, paymentLogByGroupId } from '../../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../aws-exports';
import { post } from 'aws-amplify/api';

const GroupMemberShow = (props) => {
const { groupName } = useContext(GroupNameContext);
const { isOpen, onClose, onOpen } = useModal();
//backとの接続で追加
const client = generateClient();
const { groupId } = useParams();
const [group, setGroup] = useState(null);

useEffect(() => {
  let isSubscribed = true;
  async function fetchGroup() {
      try {
          const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
          if (isSubscribed) {
              console.log('Fetched group:', response.data.getGroup);
              setGroup(response.data.getGroup);
          }
          console.log('Payments:')
      } catch (error) {
          console.error('Error fetching group:', error);
      }
  }

  fetchGroup();
  return () => {
      isSubscribed = false; // Cleanup function to prevent setting state on unmounted component
  };
}, [groupId]);

if (!group) {
  return <div>Loading...</div>; // Add a loading state
}


return (
    <>
     {group && (
    <div className="pannding2vh">
      <h2 className="groupTitleEditEng magrin-bottom0vh">Group Name</h2>
      <div className="groupNameState border-bottom backgroundffffff">
        <p onClick={onOpen} className="groupNameEdit">{group.name}</p>
      </div>
        {isOpen && (
          <ModalPortal>
            <Modal onClose={onClose} />
          </ModalPortal>
        )}
    </div>
     )}
    </>
  );
};

export default GroupMemberShow;
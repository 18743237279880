import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import WaricanMemoryTitle from "./WaricanMemoryTitle/WaricanMemoryTitle";
import WaricanMemoryPicture from "./WaricanMemoryPicture/WaricanMemoryPicture";
import WaricanMemoryEditButton from "./WaricanMemoryEditButton/WaricanMemoryEditButton";
import WaricanMemoryLocation from './WaricanMemoryEditLocation/WaricanMemoryLocation';
import WaricanMemoryComment from './WaricanMemoryComment/WaricanMemoryComment';
import WaricanMemoryMember from './WaricanMemoryMember/WaricanMemoryMember';
import WaricanMemoryMoney from './WaricanMemoryMoney/WaricanMemoryMoney';
import WaricanMemoryCreateDate from "../WaricanMemory/WaricanMemoryCreateDate/WaricanMemoryCreateDate";
import WaricanEditContext from "./WaricanContext/WaricanEditContext";
import PinIcon from "./PinIcon"
import "./WaricanMemory.css"

/**
 * Renders the WaricanMemory component.
 *
 * @param {Array} paymentLogs - The array of payment logs. 親コンポーネントから渡される支払いログの配列
 * @param {Object} group - The group object.　親コンポーネントから渡されるグループオブジェクト
 * @returns {JSX.Element} The rendered WaricanMemory component.
 */
const WaricanMemory = ({ paymentLogs = [], imageUrls, members }) => {

  const navigate = useNavigate();
  const { groupId } = useParams();
  const [indexNumber, setIndexNumber] = useState([]);

  const handleClick = (index) =>{
    setIndexNumber(index)
    console.log("選択した配列は"+indexNumber)
    navigate(`/Outlet/${groupId}/PaymentLogsEdit`)
  }

  if (!paymentLogs || paymentLogs.length === 0) {
    return <div><br/><br/>まだ支払いが登録されていません</div>;
  }

  const IconComponent = () => (
    <svg className='waricanMemoryEditButtonIcon' viewBox="0 0 22 22">
      <path fill="none" d="M0 0h24v24H0z">
      </path>
      <path d="M22 24H2v-4h20v4zM13.06 5.19l3.75 3.75L7.75 18H4v-3.75l9.06-9.06zm4.82 2.68-3.75-3.75 1.83-1.83a.996.996 0 0 1 1.41 0l2.34 2.34c.39.39.39 1.02 0 1.41l-1.83 1.83z">
      </path>
    </svg>
  );
  
  const defaultProps = {
    IconComponent,
  };  

  // return (   
  //   <>
  //   {Array.isArray(paymentLogs) && (
  //   <div className='waricanMemoryDisplay'>
  //     {paymentLogs.map((items,index) => (
  //       <React.Fragment key={index}>
  //         <div>
  //           <WaricanMemoryCreateDate date = {items.createdAt}/>
  //         </div>
  //         <div key={index} className='waricanMemory'>
  //           <WaricanMemoryPicture url={imageUrls[index]} />
  //           <WaricanMemoryTitle name={items.name} />
  //           <WaricanMemoryEditButton onClick={()=>handleClick(index)} indexNumber={indexNumber}/>
  //           <WaricanMemoryLocation location={items.location} />
  //           <WaricanMemoryComment comment={items.message} />
  //           <WaricanMemoryMember payer={items.payer} bePaidMembers={ members.filter((member, i) => items.details[i] !== 0)} />
  //           <WaricanMemoryMoney amount={items.amount} />
  //         </div>
  //       </React.Fragment>
  //       ))}
  //   </div>
  //   )}
  //   </>
  // );

  return (   
    <>
    {paymentLogs && (
    <div className='waricanMemoryDisplay'>
      {paymentLogs.map((items,index) => (
        <>
        <div key={index} className='waricanMemory'>
          <div className="yokonarabe">
            <WaricanMemoryCreateDate date = {items.createdAt}/>
            {/* <button className='waricanMemoryEditButton' 
              onClick={() => navigate(`/Outlet/${groupId}/PaymentLogsEdit/`, { state: { currentLog: items, currentMembers: members } })}>
              {
                IconComponent 
                ? <IconComponent className='waricanMemoryEditButtonIcon' /> 
                : <defaultProps.IconComponent />
              }
            </button> */}
          </div>
          <WaricanMemoryTitle name={items.name} />
          <WaricanMemoryPicture url={imageUrls[index]} />
          <WaricanMemoryComment comment={items.message} />
          <div className="yokonarabe2 width41">
            <WaricanMemoryMember payer={items.payer} bePaidMembers={ members.filter((member, i) => items.details[i] !== 0)} />
          </div>
          <WaricanMemoryMoney amount={items.amount} />
        </div>
        </>
      ))}
    </div>
    )}
    </>
  );
};

export default WaricanMemory;
import React from 'react';
import "./EditBottun.css"
import { useNavigate} from 'react-router-dom'; 

const IconComponent = () => (
  <svg className='editIcon'  viewBox="0 0 24 24">
    <path d="M0 0h24v24H0V0z" fill="none">
    </path>
    <path d="M5 18.08V19h.92l9.06-9.06-.92-.92z" opacity=".3">
    </path>
    <path d="M20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83zM3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19z">
    </path>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const EditButton = (props) => {

    const navigate = useNavigate(); // 追加

    const handleButtonClick = () => {
        navigate('/GroupMemberEdit'); // 追加
      };

  return (
    <button className='editButton' onClick={props.onClick}>
      {
        props.IconComponent 
          ? <props.IconComponent className='editIcon' /> 
          : <defaultProps.IconComponent />
      }
    </button>
  );
};

export default EditButton;

import {useContext,useEffect,useState} from "react";
import "../styles.css";
import "./CreateGroup.css"
import "./MyGroupMember.css"
import MyGroupMember from './MyGroupMember';
import { useNavigate} from 'react-router-dom'; // 追加
import GroupMemberContext from './GroupMemberContext';
import GroupNameContext from "./GroupNameContext"
import GroupNameIuput from "./GroupNameIuput";
import SceneBottun from "./SceneBottun"
import SceneIcon from "./SceneIcon";
import GaikaContext from "./GaikaContext";
import GaikaInput from "./GaikaInput"
import Header from "../Header/Header"
import Footer from "../Footer/Footer.js";
import MyGroupMemberAdd from "./MyGroupMember/MyGroupMemberAdd"
import ReturnBottun from "./ReturnBottun/ReturnBottun.jsx"
import { FadeIn} from '../CommonComponents/FadeIn.tsx';
import { createGroup } from './../graphql/mutations';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

const CreateGroup = () => {
  const { groupName , setGroupName } = useContext(GroupNameContext);//追加
  const { gaika, setGaika } = useContext(GaikaContext);//追加
  const navigate = useNavigate(); // 追加
  //グループメンバーの追加の使用する
  const [members, setMembers] = useState([]);
  const [memberName, setMemberName] = useState('');
  const [memberNames, setMemberNames] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState('');       // エラーメッセージ


  //グループメンバーの追加
  //グループメンバー名の入力
  const handleMemberInputChange = (event) => {
    setMemberName(event.target.value);
  };

  //メンバーの登録
  const handleAddMember = (event) => {
    event.preventDefault(); // デフォルトの送信をキャンセル
    if (memberName === '') {
      setErrorMessage('メンバ名を入力してください');
      return;
    }
    setMembers([...members, { name: memberName}]);
    setMemberNames([...memberNames, memberName]);
    setMemberName('');
    setErrorMessage('');
  };

  //登録メンバーの削除
  const handleRemoveMember = (index) => {
    const updatedMembers = [...members];
    const updatedMemberNames = [...memberNames];
    updatedMembers.splice(index, 1);
    updatedMemberNames.splice(index, 1);
    setMembers(updatedMembers);
    setMemberNames(updatedMemberNames);
  };

  //削除アイコン
  const IconComponent = () => (
    <svg className='deletebuttonicon'  viewBox="2.5 0 19 24">
      <path d="M0 0h24v24H0z" fill="none">
      </path>
      <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
      </path>
    </svg>
  );

  const defaultProps = {
    IconComponent,
  };

  //グループ作成コールバック
  const handleCreateGroup = async () => {
    // const input = {
    //     name: groupName,
    //     members: memberNames,
    //     tempAmount: Array(memberNames.length).fill(0),
    //     realAmount: Array(memberNames.length).fill(0)
    // };

    const input = {
      name: '',
      members: [],
      tempAmount: [],
      realAmount: [],
    }

    // エラーメッセージをリセット
    setError('');

    // グループ名が未入力の場合はエラーメッセージを表示
    if (groupName === '') {
      setError('グループ名を入力してください');
      return;
    } else {
      input.name = groupName;
    }

    // メンバーが未入力の場合はエラーメッセージを表示
    if (memberNames.length === 0) {
      setError('メンバーを追加してください');
      return;
    } else {
      input.members = memberNames;
      input.tempAmount = Array(memberNames.length).fill(0);
      input.realAmount = Array(memberNames.length).fill(0);
    }

    //コンソールでinputを確認
    // console.log('Mutation input:', input);

    try {   
        const response = await client.graphql({ query: createGroup, variables: { input } });
        // console.log('Mutation response:', response);
        // IDを取得してgroupIdに格納
        const groupId = response.data.createGroup.id;
        // console.log('Group ID:', groupId);
        navigate(`/Outlet/${groupId}`);
    } catch (error) {
        console.error('Error performing mutation:', error);
        const groupId = 'noApiResponse-it-is-test-id'
        navigate(`/Outlet/${groupId}`);
    }
  };
  
  return (
    <>
    <div className="App bg_pattern backgroundColor38B48B" style={{overflowY: 'auto'}}>
      <Header/>
      <div className="pandding2vh">
        <h3 className="themeEngTop1">New group</h3>
        <h3 className="themeJpnTop">グループ作成</h3>
      </div>
        <div className="backgroundColorWhite">
          <div className="border-top2 backgroundColorWhite"></div>
          <div className="border-top2 margin-top05vh panddingright2vh"></div>
          <div className="border-top2 margin-top05vh panddingright2vh"></div>
          <div className="border-top2 margin-top05vh panddingright2vh"></div>
        </div>

        <div className='backgroundColorWhite Paper_v2'>
          <div className='screenState'>
            <div className="fusenState">
              <div className="fusen backgroundColorf5f6f8">
                <p className="noteFont5">Step1</p>
                <h1 className="stepExplainMoji">グループの名前を入力✍️</h1>
                <GroupNameIuput/>
              </div>
            </div>
          
          <FadeIn>
          <div className="fusenState">
          <div className="fusen backgroundColorf5f6f8">
              <p className="noteFont5">Step2</p>
              <h1 className="stepExplainMoji">💃メンバーを登録🕺</h1>
              <div>
                <div className="memberAddState2">
                  <form  onSubmit={handleAddMember} >
                    <input
                      type="text"
                      placeholder="メンバーの名前"
                      value={memberName}
                      onChange={handleMemberInputChange}
                      className='memberNameInputText'
                    />
                    <MyGroupMemberAdd/>
                    
                    {errorMessage && <div>{errorMessage}</div>}
                  </form>
                </div>

                <ul className="group-members">
                  {members.map((member,index) => (
                      <div
                        key={index} 
                        className="group-memberCreate" 
                        >
                        <section className='groupMembersNameRation'>
                        <p/>
                        {member.name}<br/>
                        </section>
                        <div className='deleteButtonPosition'>
                        <button className="deletebutton" onClick={() => handleRemoveMember(index)}>
                          {
                            IconComponent 
                              ? <IconComponent className="deletebuttonicon" /> 
                              : <defaultProps.IconComponent />
                          }
                        </button>
                        </div>
                      </div>
                  ))}
                </ul>
                </div>
                </div>
          </div>
          </FadeIn>
          
          <FadeIn>
          <div className="fusenState">
          <div className="fusen backgroundColorf5f6f8">
          <h2 className="noteFont5">Step3</h2>
            <h1 className="stepExplainMoji">当てはまるシーンを選択☑️</h1>
                  <SceneBottun/>
            </div>
          </div>
          </FadeIn>
        
          <FadeIn>
          <div className="fusenState">
          <div className="fusen backgroundColorf5f6f8">
            <h2 className="noteFont5">Step4</h2>
              <h1 className="stepExplainMoji">外貨💰を選択(任意)</h1>
              <div className="step5ButtonState">
              <GaikaInput/>
              </div>
            </div>
          </div>
          </FadeIn>
        
          <FadeIn>
          <div className="fusenState">
          <div className="fusen backgroundColorf5f6f8">
              <h2 className="noteFont5">Step5</h2>
              <h1 className="stepExplainMoji">作成😆</h1>
              <div className="step5ButtonState">
              <div className="hidarimargin">
                  <ReturnBottun/>
                </div>
                <div className="leftmargin">
                  <button  className = 'submitBottun' onClick={handleCreateGroup}>作成</button>
                </div>
              </div>
              </div>
          </div>
          </FadeIn>
        </div>
      </div>
      <div className="backgroundColorWhite">
        {error && <div className="error" style={{ color: 'red' }}>{error}</div>}
        <div className="border-top2"></div>
        <div className="border-top2 margin-top05vh"></div>
        <div className="border-top2 margin-top05vh"></div>
        <div className="border-top2 margin-top05vh"></div>
      </div>
      <h1 className="margin-bottom5vh"></h1>
      <Footer/>
    </div>
    </>
  );
}


export default CreateGroup;

//MyGroupMemberはコンポーネントであるが、引数を定義しているためコンポーネントの呼び出し時に引数を受け渡すように記述している

import React from 'react';

const styles = {
  Icon: {
    color: '#000000',
    fill: '#000000',
    fontSize: '3rem',
    width: '3rem',
    height: '3rem',
    margin: '2vh',
  },
};

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#000000">
    <path d="M349.07-381.07h391l-127-171-103 135-68-87-93 123Zm-82.85 182.98q-27.6 0-47.87-20.26-20.26-20.27-20.26-47.87v-555.69q0-27.7 20.26-48.04 20.27-20.33 47.87-20.33h555.69q27.7 0 48.04 20.33 20.33 20.34 20.33 48.04v555.69q0 27.6-20.33 47.87-20.34 20.26-48.04 20.26H266.22Zm0-68.13h555.69v-555.69H266.22v555.69ZM138.09-69.72q-27.7 0-48.04-20.33-20.33-20.34-20.33-48.04v-623.82h68.37v623.82h623.82v68.37H138.09Zm128.13-752.19v555.69-555.69Z"/>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const Icon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent /> 
      : <defaultProps.IconComponent />
  );
};

export default Icon;
import React, { useState } from "react";

export default function Popup({ isOpen, onClose }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-content">
        <h2>ポップアップタイトル</h2>
        <p>ポップアップ内容</p>
        <button onClick={onClose}>閉じる</button>
      </div>
    </div>
  );
}

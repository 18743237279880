import React from 'react';
import "./SitaArow.css"


const IconComponent = () => (
  <svg className='shitaArowicon'  viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z">
    </path>
    <path d="m19 15-1.41-1.41L13 18.17V2h-2v16.17l-4.59-4.59L5 15l7 7 7-7z">
    </path>
  </svg>
);

const defaultProps = {
  IconComponent,
};

const Icon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent className="shitaArowicon" /> 
      : <defaultProps.IconComponent />
  );
};

export default Icon;
import React from 'react';
import CreateGroup from './GroupCreatPage/CreateGroup';
import Top from './TopPage/Top';
import InputAccount from './InputAccount/InputAccount'
import { Routes, Route } from 'react-router-dom';
import CreatePage from "./GroupScreen/GroupPage"
import GroupMemberEdit from './GroupMemberEdit/GroupMemberEdit';
import PaymentLogsEdit from './PaymentLogsEdit/PaymentLogsEdit';
import CreaterInfo from './CreaterInfo/CreaterInfo';
import WattchaIntro from './WattchaIntro/WattchaIntro';
import Outlet from './Outlet';


const route = (
  <Routes>
    <Route path="/" element={<WattchaIntro />} />
    <Route path='/createrinfo' element={<CreaterInfo/>}/>
    <Route path="/createGroup" element={<CreateGroup />} />
    <Route path="/Outlet" element={<Outlet/>}>
      <Route path=":groupId" element={<CreatePage />}/>
    </Route>
    <Route path="/Outlet/:groupId/InputAccount" element={<InputAccount />} />
    <Route path="/Outlet/:groupId/GroupMemberEdit" element={<GroupMemberEdit />} />
    <Route path="/Outlet/:groupId/PaymentLogsEdit" element={<PaymentLogsEdit />} />
  </Routes>
);

export default route;

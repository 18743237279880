import { useEffect, useState, useContext,React} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import './InputAccount.css'
import Header from "../Header/Header";
import InputForm from "./InputForm.jsx";
import InputImageField from "./InputImage/InputImageField.tsx"; //typsriptは拡張子まで書かないといけない
import LocationForm from "./LocationForm/LocationForm.jsx"
import CommentForm from "./CommentForm/CommentForm.jsx"
import SelectMember from "./SelectMember/SelectMember.jsx"
import SelectMemberTo from "./SelectMember/SelectMemberTo.jsx"
import InputMoney from "./InputMoney/InputMoney.jsx"
import CreateBottun from "./CreateBottun/CreateBottun.jsx"
import ReturnBottun from "./ReturnBottun/ReturnBottun.jsx"
import ShitaArow from "./Icon/SitaArow.jsx"
import RationSetBottun from "./RationSetBottun/RationSetBottun.jsx";
import PayNameContext from "./Context/PayNameContext.js";
import PayerNameContext from "./Context/PayerNameContext.js";
import AmountContext from "./Context/AmountInputContext.js";
import MessageContext from "./Context/MessageContext.js";
import ImageContext from "./Context/ImageContext.js";
import LocationContext from "./Context/LocationContext.js";
import { FadeIn2 } from "../CommonComponents/FadeIn2.tsx";

import { getGroup } from './../graphql/queries';
import { createPaymentLog, updateGroup } from './../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';

const client = generateClient();

/**
 * InputAccountコンポーネントは、会計情報の入力を行うためのフォームを表示します。
 * 
 * @returns {JSX.Element} InputAccountコンポーネントのJSX要素
 */
const InputAccount = () => {


  /** 変数定義 (ページ切り替え関係) **/
  const { groupId } = useParams();  // 前のページからgroupIdを取得
  const navigate = useNavigate();  //ページ移動のため定義

  /** 変数定義 (useState周り) **/
  const [group, setGroup] = useState({});       // グループ情報 ページに遷移と同時に取得
  const [name, setName] = useState('');         // 支払い名
  const [amount, setAmount] = useState(0);     // 支払金額
  const [payer, setPayer] = useState('');       // 支払者
  const [weights, setWeights] = useState([]);    // 支払い比率
  const [details, setDetails] = useState([]);   // 個人ごとの負担金額
  const [location, setLocation] = useState(''); // ロケーション (任意)
  const [message, setMessage] = useState('');   // メッセージ (任意)
  const [image, setImage] = useState(null);     // 画像オブジェクト (任意)
  const [splitMethod, setSplitMethod] = useState('evenly')  // 支払い方法
  const [error, setError] = useState('');       // エラーメッセージ
  const [onLoadingImage, setOnLoadingImage] = useState(false); // 画像のローディング状態
  const [onSubmit, setOnSubmit] = useState(false); // 送信ボタンのローディング状態

  /** 変数定義 (コールバック回り) **/

  /** 名前の変更を処理する関数です。
   * 
   * @param {Event} event - イベントオブジェクト
   */
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  /** 入力された金額を処理する関数
   * 
   * @param {Event} event - イベントオブジェクト
   */
  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  /** 支払者の変更を処理する関数
   * 
   * @param {Object} member - グループメンバーオブジェクト
   */
  const handlePayerChange = (member) => {
    setPayer(member);
  };

  /** 支払い比率の変更を処理する関数
   * 
   * @param {Event} event - イベントオブジェクト
   */
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  /** メッセージの変更を処理する関数
   * 
   * @param {Event} event - イベントオブジェクト
   */
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  /** 画像の変更を処理する関数
   * 
   * @param {Event} event - イベントオブジェクト
   */ 
  const handleImageChange = (event) => {
    const file = event.target.files[0];
      if (file) {
        setImage(file);
    }
  };

  /** 支払金額の分配方法が均等の場合の処理を行う関数 */
  const evenlyButtonClicked = () => {
    setSplitMethod('evenly');
    setWeights(weights.map((weight) => {
      if (weight != 0) {
        return 1;
      } else {
        return 0;
      }
    }));
  }

  /** 支払金額の分配方法が重み付けの場合の処理を行う関数 */
  const weightedButtonClicked = () => {
    setSplitMethod('weighted');
  }

  /** 支払い比率の変更を処理する関数
   * 
   * @param {Array} newWeights - 支払い比率の配列
   * @returns {void}
   */ 
  const handleSetWeights = (newWeights) => {
    // setWeightsを使って、weightsの値を更新する
    setWeights(newWeights);
  }

  /** 支払い比率から個人ごとの負担金額を計算する関数
   * 
   * @returns {void}
   */ 
  const handleSetDetails = () => {
    if (amount>0) {
      const sumWeights = weights.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0)
      setDetails(weights.map((weight) => {
        const detail = amount * weight / sumWeights
        return detail.toFixed(2);
      }));
    } else {
      setDetails(weights.map(() => {
        return 0;
      }));
    }
  }
  

  /** groupIdに値が入った時（このページに移動してきたとき）にグループ情報を取得する */
  useEffect(() => {
    /** グループ情報を取得する非同期関数です。
     * @async
     * @function fetchGroup
     * @returns {Promise<void>}
     */
    async function fetchGroup() {
      try {
        const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
        console.log('Fetched group is successful!');
        setGroup(response.data.getGroup);
        setPayer(response.data.getGroup.members[0]); // Initialize the payer as the first member
      } catch (error) {
          console.error('Error fetching group:', error);
          const dammyGroup = {
            id: 'noApiResponse-it-is-test-id',
            name: 'No API Response',
            members: ['ひとし', 'ゆきな', 'ながさわ'],
            tempAmount: [0, 0, 0],
            realAmount: [0, 0, 0]
          }
          setGroup(dammyGroup)
          setPayer(dammyGroup.members[0]);
          console.log('dammy group is set')
      }
    }
    fetchGroup();
  }, []);

  useEffect(() => {
    if (group.members) {
      setWeights(group.members.map(() => (
        1
      )));
      setDetails(group.members.map(() => (
        0
      )));
    }
  }, [group]);


  /** 応答処理　useEffect 
   * useStateの変数が変更されるたびにコンソールに表示される
  */
  useEffect(() => {
    console.log('name:', name);
  }, [name]);

  /**一人当たりの金額を更新 */
  useEffect(() => {
    console.log('amount:', amount);
    handleSetDetails();
  }, [amount]);
  
  useEffect(() => {
    console.log('payer:', payer);
  }, [payer]);

  useEffect(() => {
    console.log('location:', location);
  }, [location]);

  useEffect(() => {
    console.log('message:', message);
  }, [message]);

  useEffect(() => {
    console.log('splitMethod:', splitMethod);
  }, [splitMethod]);

  useEffect(() => {
    console.log('details:', details);
  }, [details]);

  /** 一人当たりの金額の更新 */
  useEffect(() => {
    console.log('weights:', weights);
    handleSetDetails();
  }, [weights]);

  const handleCreatePaymentLog = async (event) => {
    event.preventDefault(); // フォーム送信のデフォルトアクションを防ぐ

    // 送信中のローディング画面を表示
    setOnSubmit(true);

    // 支払い情報の初期化
    let paymentLogInput = {
      input: {
        groupId: groupId,
        name: '',
        amount: 0,
        payer: '',
        weights: [],
        details: [],
        location: null,
        message: null,
        s3Image: null
      }
    };
    
    // 必須項目が入力されているかチェック
    // 支払い名
    if (name === '') {
      setError('支払い名を入力してください');
      return;
    } else {
      paymentLogInput.input.name = name;
    }

    // 金額
    if (amount > 0) {
      paymentLogInput.input.amount = amount;
    } else {
      setError('金額を入力してください');
      return;
    }

    // 支払者
    paymentLogInput.input.payer = payer;

    // 支払い比率
    paymentLogInput.input.weights = weights;

    // 個人ごとの負担金額、全員が0円の場合はエラー
    if (details.every((detail) => detail === 0)) {
      setError('金額を入力してください');
      return;
    } else {
      paymentLogInput.input.details = details;
    }

    // エラーメッセージをリセット
    setError('');

    // ロケーションがあればセット
    if (location !== '') {
      paymentLogInput.input.location = location;
    }

    // メッセージがあればセット
    if (message !== '') {
      paymentLogInput.input.message = message;
    }

    // 画像があればS3にアップロード
    if (image) {
      try {
          const timestamp = Date.now();
          const result = await uploadData({
              path: `public/${groupId}/${timestamp}${image.name}`, // ここでS3バケットのパスを指定
              data: image,
              options: {
                  contentType: image.type,
                  onProgress: ({ transferredBytes, totalBytes }) => {
                      if (totalBytes) {
                          console.log(`Upload progress: ${(transferredBytes / totalBytes) * 100}%`);
                      }
                  }
              }
          }).result;
          const imagePath = `public/${groupId}/${timestamp}${image.name}`;
          paymentLogInput.input.s3Image = imagePath;

      } catch (error) {
          console.error('Error uploading image:', error);
          setError('画像のアップロードに失敗しました');
      }
  }

    try {
      // PaymentLog を作成
      const response = await client.graphql({
        query: createPaymentLog,
        variables: paymentLogInput
      });
      console.log('Payment Log Created:', response);
    
      // tempAmount と realAmount の更新
      const updatedTempAmount = [...group.tempAmount];
      const updatedRealAmount = [...group.realAmount];

      // 支払者の負担金額を追加
      const payerIndex = group.members.indexOf(payer);
      updatedTempAmount[payerIndex] += parseInt(amount);

      // 個人の負担金額を追加
      details.forEach((detail, index) => {
        updatedRealAmount[index] += parseFloat(detail);
      });

      // グループ情報更新用のAPI入力の作成
      const updateGroupInput = {
        id: groupId,
        tempAmount: updatedTempAmount,
        realAmount: updatedRealAmount
      };

      // グループ情報更新のAPIを呼び出し
      // Group の更新
      await client.graphql({
        query: updateGroup,
        variables: { input: updateGroupInput }
      });

      // Outlet ページに遷移
      navigate(`/Outlet/${groupId}`);

    } catch (error) {
      console.error('Error creating Payment Log:', error);
    } finally {
      // ローディング画面を非表示
      setOnSubmit(false);
    }
  };

  /** グループ情報をAPIから取得するまではローディング画面を表示 */
  if (!group.members) {
    return <div>Loading...</div>; 
  }

  if (onLoadingImage) {
    return <div>画像を読み込み中...</div>;
  }

  if (onSubmit) {
    return <div>支払い情報を登録中...</div>;
  }

  return (
    <div className="App bg_pattern Paper_v2" style={{overflowY: 'auto'}}>
      <Header/>
      <div className="inputAccountTitle">
        <div className="inputAccountTitleinner inputImageLocation">
          <div className="inputAccountTitleinnerin">
            <h1 className="inputAccountEng">Register accounting</h1>
            <h2 className="inputAccountJpn">会計の登録</h2>
          </div>
        </div>
      </div>

      <br/>
      <br/>

      <div className="inputpayNameTitle">
        <div className="inputNameRegisterinner">
          <div>
            <h4 className="inputPayName">支払い名を登録</h4>
            <div className="payNameRegisterState">
              <InputForm command={handleNameChange}/>
            </div>
          </div>
        </div>
      </div>

      <br/>
      <br/>

      <div className="inputwarikan">
        <div>
          <h4 className="inputwarikaninnerName">割り勘の入力</h4>
          <div className="inputwarikanLocation">
            <SelectMember members={group.members} command={handlePayerChange} payer={payer}/>
            <div className="inputDetail">
              <ShitaArow/>
              <InputMoney command={handleAmountChange}/>
              <ShitaArow/>
              <RationSetBottun 
                splitMethod={splitMethod}
                commandEvenly={evenlyButtonClicked}
                commandWeighted={weightedButtonClicked}/>
              <SelectMemberTo
                members={group.members}
                weights={weights}
                details={details}
                splitMethod={splitMethod}
                commandSetWeights={handleSetWeights}
                commandSetDetails={handleSetDetails}/>
            </div>
          </div>
        </div>
      </div>
      
      <br/>
      <br/>
      
      <div className="inputPictureGPSComment">
        <div>
          <h4 className="inputPictureGPSCommentinnerName">写真と場所の記録 (任意)</h4>
          <div className="inputImageLocationState">
            <div className="inputImageLocation">
              <InputImageField image={image} command={setImage} commandLoading={setOnLoadingImage}/>
              <CommentForm command={handleMessageChange} />
            </div>
            <LocationForm command={handleLocationChange}/>
          </div>
        </div>
      </div>

      {error && <div className="error" style={{ color: 'red' }}>{error}</div>}

      <ReturnBottun onClick={()=>navigate(`/Outlet/${groupId}`)}/>
      <CreateBottun command={handleCreatePaymentLog}/>

    </div>
      
  );
};
  
export default InputAccount;
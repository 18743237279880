import React, { useState, useEffect,useContext } from 'react';
import "./LocationForm.css"


const LocationForm = (props) => {

    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
  

    //支払い者の入力

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (err) => {
        setError(err.message);
        console.log('location error: ', err.message)
      }
    );
  }, []);

  return (
    <div className='location'>
      <button className="locationBottun" onClick={() => {}}>
      {props.IconComponent 
      ? <props.IconComponent style={styles.Icon} /> 
      : <defaultProps.IconComponent />
      }
      </button>
      {latitude && longitude && (
        <div className='resultBox' onChange={props.command}>
          緯度: {latitude}
          経度: {longitude}
        </div>
      )}
      {error && <div>エラー: {error}</div>}
    </div>
  );
};

const styles = {
  Icon: {
    color: '#030303',
    fill: '#030303',
    fontSize: '19px',
    top: '127px',
    left: '135px',
    width: '19px',
    height: '24px',
  },
};

const IconComponent = () => (
  <svg style={styles.Icon}  viewBox="0 0 384 512">
    <path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z">
    </path>
  </svg>
);

const defaultProps = {
  IconComponent,
};



export default LocationForm;

/*
Googleから位置情報を取得し、お店の名前を表示するコード(Google Maps Platform API キーが必要)
import React, { useState, useEffect } from 'react';
import "./LocationForm.css"
import { GoogleMap, LoadScript } from "@google/maps-react";

const LocationForm = (props) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [address, setAddress] = useState("");
  const [placeName, setPlaceName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (err) => {
        setError(err.message);
      }
    );
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(latitude, longitude);
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setAddress(results[0].formatted_address);
            setPlaceName(results[0].place_name);
          }
        } else {
          setError("住所の取得に失敗しました。");
        }
      });
    }
  }, [latitude, longitude]);

  return (
    <div>
      <button className="locationForm" onClick={() => {}}>位置情報の取得</button>
      {latitude && longitude && (
        <div>
          緯度: {latitude}
          経度: {longitude}
          <br />
          住所: {address}
          <br />
          場所名: {placeName}
        </div>
      )}
      {error && <div>エラー: {error}</div>}
    </div>
  );
};

export default LocationForm;



*/
import { Outlet } from 'react-router-dom';

function outlet() {
    return (
        <>
            <Outlet />
        </>
    );
  }
  
  export default outlet;
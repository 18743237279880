import React from 'react';
import "./WaricanMemoryCreateDate.css"
const InputField = (props) => {

    const date = props.date
    const Date = date.slice(0, 10);
    
  return (
    <p className='waricanMemoryCreateDate'>{Date}</p>
  );
};

export default InputField;

//CSSの配置はよう確認
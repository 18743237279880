import React from 'react';

const styles = {
  Icon: {
    color: '#000000',
    fill: '#000000',
    fontSize: '3rem',
    width: '3rem',
    height: '3rem',
    margin: '2vh',
  },
};

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#00A9DB"><path d="M279.76-142.15v-469.72q0-28.66 19.77-48.63 19.77-19.98 48.12-19.98h470.2q28.45 0 48.41 19.96 19.96 19.96 19.96 48.41v336.18L684.07-74.02H347.89q-28.35 0-48.24-19.89t-19.89-48.24ZM75.02-723.33q-5.48-28.34 10.89-50.7 16.38-22.36 44.81-27.86l462.95-82.09q28.35-5.48 50.82 10.39 22.47 15.88 27.99 44.31l15.52 88.8h-66.22L607.75-819 141-737.2l78.76 448.09v84.91q-23.11-2.23-40.12-17.16-17.01-14.93-21.53-39.01L75.02-723.33Zm271.2 109.31v473.54h303.45v-169.85h170.09v-303.69H346.22Zm236.65 236.89Z"/></svg>
);

const defaultProps = {
  IconComponent,
};

const Icon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent /> 
      : <defaultProps.IconComponent />
  );
};

export default Icon;
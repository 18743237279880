import React from 'react';

const styles = {
  Icon: {
    color: '#000000',
    fill: '#000000',
    fontSize: '3rem',
    width: '3rem',
    height: '3rem',
    margin: '2vh',
  },
};

const IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 -960 960 960" width="48px" fill="#38b48b"><path d="M288.5-536.65q-56.09 0-95.28-39.39-39.2-39.38-39.2-95.38 0-56 39.38-95.28t95.38-39.28q56 0 95.28 39.36 39.29 39.35 39.29 95.32 0 56.08-39.39 95.36-39.38 39.29-95.46 39.29Zm-.05-68.13q27.71 0 47.24-19.4t19.53-47.11q0-27.71-19.4-47.01-19.4-19.31-47.11-19.31t-47.01 19.17q-19.31 19.18-19.31 46.89t19.17 47.24q19.18 19.53 46.89 19.53Zm382.76 450.76q-55.93 0-95.24-39.34-39.32-39.33-39.32-95.34 0-56.02 39.39-95.33 39.38-39.32 95.46-39.32 56.09 0 95.4 39.49 39.32 39.49 39.32 95.35 0 55.86-39.54 95.17-39.54 39.32-95.47 39.32Zm.08-68.13q27.71 0 47.01-19.35 19.31-19.35 19.31-46.99t-19.17-47.06q-19.18-19.43-46.89-19.43t-47.24 19.35q-19.53 19.35-19.53 46.99t19.4 47.06q19.4 19.43 47.11 19.43Zm-469.53 68.13-47.74-47.74 604.46-604.46 47.74 47.74-604.46 604.46Z"/></svg>
);

const defaultProps = {
  IconComponent,
};

const Icon = (props) => {
  return (
    props.IconComponent 
      ? <props.IconComponent /> 
      : <defaultProps.IconComponent />
  );
};

export default Icon;
import { useRef, useState, useContext } from "react";
import InputImage from "./InputImage.tsx";
import React from "react";
import "./InputImage.css";
import { processImage } from '../../processImage'

const IMAGE_ID = "imageId";

function InputImageField({ image, command, commandLoading }) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget.files[0]) {

      commandLoading(true); // 画像処理中のローディング表示

      const targetFile = e.currentTarget.files[0];


      try {
        const processedImage = await processImage(targetFile);
        command(processedImage);

      } catch (e) {
        console.error(e);
      } finally {
        commandLoading(false); // 画像処理中のローディング非表示
      }
    }
  };

  const handleClickCancelButton = () => {
    command(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <div className="inputImage">
      <label
        htmlFor={IMAGE_ID}
        style={{
          border: "#fa4a00 4px solid",
          width: 110,
          height: 100,
          display: "flex",
          borderRadius: 12,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          cursor: "pointer",
          backgroundColor: "#ffffff",
        }}
      >
        {image ? (
          <img
            src={URL.createObjectURL(image)}
            alt="アップロード画像"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        ) : (
          command.IconComponent ? (
            <command.IconComponent style={styles.Icon} />
          ) : (
            <defaultProps.IconComponent />
          )
        )}

        <InputImage ref={fileInputRef} id={IMAGE_ID} onChange={handleFileChange} />
      </label>

      <button className="cancelButton" onClick={handleClickCancelButton}>
        キャンセル
      </button>
    </div>
  );
}

export default InputImageField;

const styles = {
  Icon: {
    color: "#030303",
    fill: "#030303",
    fontSize: "32px",
    top: "159px",
    left: "58px",
    width: "32px",
    height: "40px",
  },
};

const IconComponent = () => (
  <svg style={styles.Icon} viewBox="0 0 24 24">
    <path d="M24 24H0V0h24v24z" fill="none" />
    <path d="M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zM5 17l3.5-4.5 2.5 3.01L14.5 11l4.5 6H5z" />
  </svg>
);

const defaultProps = {
  IconComponent,
};

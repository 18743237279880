import React, { useContext, useState,useEffect } from "react";
import GroupNameContext from "../../../GroupCreatPage/GroupNameContext";
import { useNavigate, useParams } from 'react-router-dom';
import { createPaymentLog, updateGroup } from './../../../graphql/mutations';
import { getGroup } from '../../../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../../aws-exports';
import { post } from 'aws-amplify/api';

import "./EditModal.css"

export const Modal = ({ onClose }) => {
  const { groupName , setGroupName } = useContext(GroupNameContext);//追加
  const [newgroupName, setNewGroupName] = useState("");
  //backとの接続で追加
  const client = generateClient();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [paymentLogs, setPaymentLogs] = useState([]);

  const handleChange = (event) => {
    setNewGroupName(event.target.value);
    console.log("名前"+newgroupName)
  };

  const submitNewName = async () =>{
    setGroupName(newgroupName);

    // Group を取得
    const groupResponse = await client.graphql({
      query: getGroup,
      variables: { id: groupId }
  });
  
    const updateGroupInput = {
        id: groupId,
        name: newgroupName,
    };

    // Group の更新
    await client.graphql({
        query: updateGroup,
        variables: { input: updateGroupInput }
    });
    
    window.location.reload();
    }

  useEffect(() => {
    let isSubscribed = true;
    async function fetchGroup() {
        try {
            const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
            if (isSubscribed) {
                console.log('Fetched group:', response.data.getGroup);
                setGroup(response.data.getGroup);
            }
            console.log('Payments:')
        } catch (error) {
            console.error('Error fetching group:', error);
        }
    }
  
    fetchGroup();
    return () => {
        isSubscribed = false; // Cleanup function to prevent setting state on unmounted component
    };
  }, [groupId]);
  
  if (!group) {
    return <div>Loading...</div>; // Add a loading state
  }

    return (
      
      <div className="container2">
         {group && (
          <>
        <div className="overlay" onClick={onClose} />
        <section className="containerInner">
          <h2 className="modalfont">グループ名の変更</h2>
          <button
            onClick={onClose}
            type="button"
            aria-label="閉じる"
            className="iconClose"
          >
            ×
          </button>
          <p className="contents modalfont">変更後のグループ名</p>
          <div>
          <input className='groupNameEditForm' placeholder={group.name} onChange={handleChange}/>

          <div className="buttonContainer">
            <button
              onClick={() => {
                submitNewName();
                onClose();
                }}
              type="button"
              className="button closeButton"
            >
              変更
            </button>
            <button type="button" onClick={onClose} className="button nextButton">
              キャンセル
            </button>
          </div>
          </div>
        </section>
        </>
         )}
      </div>
    );
  };
  
  //onClickに複数書く場合は、onClick={() => {submitNewName(); onClose(); }}と記載する
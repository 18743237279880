import {useContext} from 'react';
import GaikaContext from './GaikaContext';
import "./GaikaInput.css"


const defaultProps = {
  values: [
    'JPY',
    'USD',
    'EUR',
  ],
};

const GroupNameIuput = (props) => {
    const { gaika, setGaika } = useContext(GaikaContext);//追加


  const handleChange = (event) => {
    setGaika(event.target.value);
    console.log('groupName:', gaika); // コンソールログに出力
  };

  return (
    <select
      className='gaikaInput'
      defaultValue={gaika}
      onChange={handleChange}
    >
      {defaultProps.values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};

export default GroupNameIuput;
import React, { useEffect } from 'react';
import "./IntroTitle.css"


const AnimationComponent = () => {

  return (
    <div class="loop-wrap">
      <ul class="loop-area">
        <li class="content">飲み会?</li>
        <li class="content">デート?</li>
        <li class="content">旅行？</li>
        <li class="content">買い物？</li>
      </ul>
      <ul class="loop-area">
        <li class="content">飲み会?</li>
        <li class="content">デート?</li>
        <li class="content">旅行？</li>
        <li class="content">買い物？</li>
      </ul>
      <ul class="loop-area">
        <li class="content">飲み会?</li>
        <li class="content">デート?</li>
        <li class="content">旅行？</li>
        <li class="content">買い物？</li>
      </ul>
    </div>
  );
};

export default AnimationComponent;
import React from 'react';
import WaricanMemoryMemberTo from './WaricanMemoryMemberTo';
import WaricanMemoryMemberIcon from './WaricanMemoryMemberIcon';
import WaricanMemoryMemberFrom from './WaricanMemoryMemberFrom';
import "./WaricanMemoryMember.css"

const WaricanMemoryMember = ({ payer, bePaidMembers }) => {

  React.useEffect(() => {
    console.log('payer:', payer);
    console.log('bePaidMembers:', bePaidMembers);
  }, [payer]);
  
  return (
    <section className='waricanMemoryMemberDisplay'>
      <WaricanMemoryMemberFrom payer = {payer}/>
      <WaricanMemoryMemberIcon/>
      <WaricanMemoryMemberTo bePaidMembers={bePaidMembers}/>
    </section>
  );
};

export default WaricanMemoryMember;
import React, { useState,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import GroupMemberShow from "./GroupMemberShow/GroupMemberShow"
import GroupTitleEdit from "./GroupTitleEdit/GroupTitleEdit"
import Header from '../Header/Header';
import ReturnBottun from "./ReturnBottun/ReturnBottun"
import "./GroupMemberEdit.css"
import { getGroup, paymentLogByGroupId } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../aws-exports';
import { post } from 'aws-amplify/api';

const GroupMemberEdit = () => {

  const navigate = useNavigate();
  const client = generateClient();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    async function fetchGroup() {
        try {
            const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
            if (isSubscribed) {
                console.log('Fetched group:', response.data.getGroup);
                setGroup(response.data.getGroup);
            }
            console.log('Payments:')
        } catch (error) {
            console.error('Error fetching group:', error);
        }
    }

    fetchGroup();
    return () => {
        isSubscribed = false; // Cleanup function to prevent setting state on unmounted component
    };
}, [groupId]);

if (!group) {
    return <div>Loading...</div>; // Add a loading state
}

  return (
    <div className='backgroundColorf6f6f6' style={{overflowY: 'auto'}}>
      <Header/>
      <div>
          {group && (
          <>
            <div className='pandding2vh'>
              <h1 className='groupNameEngEdit1'>Edit Group</h1>
              <h3 className='groupNameJpaEdit1'>グループ情報の編集</h3>
            </div>
              <p className='groupEditIntro'>グループ名、メンバー名をタッチすると<br />編集することができます</p>
            <div className='Paper_v2 backgroundColorcf9f9f9 border'>
              <GroupTitleEdit />
              <GroupMemberShow />
            </div>
            <ReturnBottun onClick={() => navigate(`/Outlet/${groupId}`)} />
          </>
        )}
      </div>
    </div>
  );
};

export default GroupMemberEdit;

import { React } from "react";
import "./CommentForm.css"

const InputField = ({command}) => {

  return (
    <textarea className='commentForm' 
    placeholder = 'コメントを入力'
    onChange={command}
     />
  );
};

export default InputField;
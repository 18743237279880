import heic2any from 'heic2any';
import Pica from 'pica';

export const processImage = async (file) => {
    let imageBlob = file;
    // 拡張子を確認し、HEICの場合はJPEGに変換
    const ext = file.name.split('.').pop().toLowerCase();
    if (ext === 'heic') {
        try {
            const convertedFile = await heic2any({
                blob: file,
                toType: 'image/jpeg'
            });
            imageBlob = convertedFile;
        } catch (error) {
            throw new Error('Failed to convert HEIC to JPEG');
        }
    } 
    // jpeg, jpg, pngの場合はリサイズ
    // それ以外の拡張子の場合はエラー
    else if (ext !== 'jpeg' && ext !== 'jpg' && ext !== 'png') {
        throw new Error('Unsupported file format');
    }

    // 画像を表示
    const img = document.createElement('img');
    img.src = URL.createObjectURL(imageBlob);

    // 画像の読み込みが完了するのを待つ
    await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
    });

    // 画像のリサイズ
    const canvas = document.createElement('canvas');
    canvas.width = 400;
    canvas.height = 400;

    const pica = new Pica();
    try {
        const result = await pica.resize(img, canvas);
        const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg'));

        if (blob) {
            console.log('Completed resizing image:', blob);
            return blob;
        } else {
            throw new Error('Failed to create blob');
        }
    } catch (error) {
        console.error(error);
        throw new Error('Failed to resize image');
    }
};

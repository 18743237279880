import { useContext, React } from 'react'; 
import './InputMoney.css'
import AmountContext from '../Context/AmountInputContext';

const defaultProps = {
    text: '金額を入力',
  };


const InputField = ({command}) => {

  return (
    <input 
      className='inputMoney'  
      placeholder={defaultProps.text} 
      onChange={command}/>
  );
};

export default InputField;
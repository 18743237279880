import React, { useContext, useState, useEffect } from "react";
import GroupMemberContext from '../../GroupCreatPage/GroupMemberContext';
import { useNavigate, useParams } from 'react-router-dom';
import "./GroupMemberShow.css"
import { Modal } from "../EditModal/GroupMemberEdit/GroupMemberEditModal";
import { useModal } from "../EditModal/GroupMemberEdit/useModal";
import { ModalPortal } from "../EditModal/GroupMemberEdit/reactPortal";
import { getGroup, paymentLogByGroupId } from '../../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../aws-exports';
import { post } from 'aws-amplify/api';


const GroupMemberShow = (props) => {
const { groupMember } = useContext(GroupMemberContext);
const { isOpen, onClose, onOpen } = useModal();
//backとの接続で追加
const client = generateClient();
const { groupId } = useParams();
const [group, setGroup] = useState(null);
const [memberName, setMemberName] = useState(null);

useEffect(() => {
  let isSubscribed = true;
  async function fetchGroup() {
      try {
          const response = await client.graphql({ query: getGroup, variables: { id: groupId } });
          if (isSubscribed) {
              console.log('Fetched group:', response.data.getGroup);
              setGroup(response.data.getGroup);
          }
          console.log('Payments:')
      } catch (error) {
          console.error('Error fetching group:', error);
      }
  }

  fetchGroup();
  return () => {
      isSubscribed = false; // Cleanup function to prevent setting state on unmounted component
  };
}, [groupId]);

if (!group) {
  return <div>Loading...</div>; // Add a loading state
}

return (
    <div>
       {group && (
        <>
      <div className="pannding2vh ">
      <h2 className="groupTitleEditEng magrin-bottom0vh">Member</h2>
      <div className="groupNameState border-bottom backgroundffffff ">
        <ul onClick={onOpen} className="groupMemberClickStyle1">
        {group.members.map((member,index) => (
            <div key={index} className="group-memberCreate1 ">
              <p>{member}</p>
            </div>
          ))}
        </ul>
        </div>
        {isOpen && (
        <ModalPortal>
          <Modal onClose={onClose} />
        </ModalPortal>
      )}
      </div>
      </>
       )}
    </div>
  );
};

export default GroupMemberShow;

/*{isOpen && (
        <ModalPortal>
          <Modal onClose={onClose} />
        </ModalPortal>
      )} */
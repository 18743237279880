import React, { useState,useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getGroup, paymentLogByGroupId } from '../../../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../../../aws-exports';
import { post } from 'aws-amplify/api';
import "./WaricanMemoryMoney.css"

function WaricanMemoryMoney(props) {
  const amount = props.amount 
  
  return (
    <>
    <div className='waricanMemoryMoney'>
      {amount}円
    </div>
    </>
  );
};

export default WaricanMemoryMoney;
import React from 'react';
import "./RationSet.css"

const defaultProps = {
  label: '比率を入力する',
};

const Button = ({command, isSelected}) => {

  return (
    <button 
    className={`rationSetButton ${isSelected ? 'selected' : ''}`} 
      onClick={command}>
      {defaultProps.label}
    </button>
  );
};

export default Button;
import React, { useState,useEffect,useContext } from 'react';
import { useNavigate, useParams,useLocation} from 'react-router-dom';
import Header from '../Header/Header';
import ReturnBottun from "./ReturnBottun/ReturnBottun"
import { getGroup, paymentLogByGroupId,getPaymentLog } from '../graphql/queries';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import awsconfig from './../aws-exports';
import EditObjects from './EditObjects/EditObjects';
import WaricanEditContext from '../GroupScreen/WaricanMemory/WaricanContext/WaricanEditContext';
import "./PaymentLogsEdit.css"

const PaymentLogsEdit = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [paylog, setPaylog] = useState(null);
    const [members, setMembers] = useState([]);

    // 渡されたデータをlocation.stateから取得
    const { currentLog, currentMembers } = location.state || {};

    useEffect(() => {
        if (currentLog) {
          setPaylog(currentLog);
          setMembers(currentMembers);
        }
        console.log("引き継いだデータは", currentLog);
        console.log("引き継いだメンバーは", currentMembers);
      }, [currentLog, currentMembers]);

    if (!paylog) {
        return <div><br/><br/>まだ支払いが登録されていません</div>;
    }


    return (
      <div className="App Paper_v2" style={{overflowY: 'auto'}}>
        <Header/>
            <div>
                <div className='pannding2vh'>
                <p className='themeEngTop1'>Edit PayLog</p>
                <p className='setumeifont'>記録の編集</p>
                <h3 className='tukaikataIntroMoji'>矢印の右側に変更後の内容を<br/>入力してください</h3>
                <div className='backgroundColorf6f6f6 border333333'>
                    <EditObjects paylog={paylog} members={members}/>
                </div>
                </div>
            </div>
      </div>
    );
  };
  
  export default PaymentLogsEdit;
import React from 'react';
import "./CreateBottun.css";
import { useNavigate} from 'react-router-dom'; 

const defaultProps = {
  label: '作成',
};

const Button = (props) => {
    const navigate = useNavigate(); // 追加

    const handleButtonClick = () => {
        navigate('/createGroup'); // 追加
      };

  return (
    <button className='createButtonTop'  onClick={handleButtonClick}>
      {props.label ?? defaultProps.label}
    </button>
        );
    };

export default Button;
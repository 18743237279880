import {useContext,useState} from 'react';
import SceneIcon from './SceneIcon';
import "./SceneIcon.css"

const SceneButton = (props) => {
    const { selectedButton , setSelectedButton } = useContext(SceneIcon);
    const [isClicked, setIsClicked] = useState(false); // 新しいステートを追加
    
const handleChange = (event) => {
    const newSelectedButton = event.target.value;
    // すでに選択されている場合は解除
    if (selectedButton === newSelectedButton) {
      setSelectedButton('');
      setIsClicked(false);
      return;
    }
    setSelectedButton(newSelectedButton);
    setIsClicked(true);
    console.log('selectedButton:', selectedButton);
  };

  return (
    <>
    <ul className='sceneState'>
      <button
        className={`sceneIcon ${selectedButton === '🍺' ? 'selected' : ''}`}
        onClick={() => handleChange({ target: { value: '🍺' } })}
      >
        のみ🍺
      </button>
      <button
        className={`sceneIcon ${selectedButton === '🧳' ? 'selected' : ''}`}
        onClick={() => handleChange({ target: { value: '🧳' } })}
      >
        旅行🧳
      </button>
      <button
        className={`sceneIcon ${selectedButton === '🎢' ? 'selected' : ''}`}
        onClick={() => handleChange({ target: { value: '🎢' } })}
      >
        遊園地🎢
      </button>
      <button
        className={`sceneIcon ${selectedButton === '💗' ? 'selected' : ''}`}
        onClick={() => handleChange({ target: { value: '💗' } })}
      >
        デート💗
      </button>
      <button
        className={`sceneIcon ${selectedButton === '🛒' ? 'selected' : ''}`}
        onClick={() => handleChange({ target: { value: '🛒' } })}
      >
        買い物🛒
      </button>
      </ul>
    </>
  );
};

export default SceneButton;

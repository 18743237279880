import React from 'react';
import "./Box.css"

const Box = ({liquidation, members, remains}) => {

  if (!liquidation || liquidation.length === 0) {
    return <div><br/><br/>まだ支払いが登録されていません</div>;
  }

  return (
    <>
      <ul className='boxs'>
      	<div className='containerbox'>
          <div className='totalAmountMemberName'>
            Name
          </div>
          <div className='totalAmountMemberName2'>
            Amount
          </div>
          <div className='totalAmountMemberName2'>
            To
          </div>
        </div>
        {liquidation.map((transaction, index) => (
          <div key={index} className='containerbox2'>
            <section className='totalAmountMemberName'>
              {members[transaction.debtor]}  {/* Display debtor's name */}
            </section>
            <section className='totalAmountMemberName2'>
              {transaction.amount}円{/* Display amount and creditor's name */}
            </section>
            <section className='totalAmountMemberName2'>
              {members[transaction.creditor]}
            </section>
          </div>
        ))}
      </ul>
    </>
  );
};

export default Box;

import React from 'react';
import "./ReturnBottun.css"
import { useNavigate} from 'react-router-dom'; 

const defaultProps = {
  label: '戻る',
};

const ReturnButton = (props) => {

    const navigate = useNavigate(); // 追加

    const handleButtonClick = () => {
        navigate('/CreatePage'); // 追加
      };

  return (
    <button className='returnButton6' onClick={props.onClick}>
      {props.label ?? defaultProps.label}
    </button>
  );
};

export default ReturnButton;
import React from 'react';
import "./URLButton.css"

const Button = ({urlCommand, lineCommand}) => {
  return (
    <div className='linkButton'>
    <button className='urlButton' onClick={urlCommand}>URL</button>
    <button className='lineButton' onClick={lineCommand}>LINE</button>
    </div>
  );
};

export default Button;
import React from 'react';
import "./WaricanMemoryMember.css"

const WaricanMemoryMember = (props) => {

  const payer = props.payer
  const firstCharPayer = payer.slice(0, 1);

  return (
    <div className='waricanMemoryMemberFrom'>
      {firstCharPayer}
    </div>
  );
};

export default WaricanMemoryMember;
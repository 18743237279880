import React, { useContext, useState ,useEffect} from "react";
import "./Selectedmember.css"

const SelectMember = ({members, command, payer}) => {

const [selectedButtons, setSelectedButtons] = useState("");

  useEffect(() => {
    // payerが変わった時だけ実行する
    const buttons = document.querySelectorAll('.group-member');
    buttons.forEach((button) => {
      const memberId = parseInt(button.getAttribute('id'), 10);
      if (members[memberId] === payer) {
        button.classList.add('selected');
      } else {
        button.classList.remove('selected');
      }
    });
  }, [payer]); // payerかmembersが変更された時だけ再実行


//onSubmitで値を送るようにする？？
  return (
    <>
    {members && (
      <ul className="group-members">
        {members.map((member, index) => (
          <button
            key={index}
            value={member}
            id={index}
            className={`group-member ${selectedButtons.includes(index) ? 'selected' : ''}`}
            onClick={() => command(member)}
          >
            {member}
          </button>
        ))}
      </ul>
    )}
    </>
  );
};

export default SelectMember;
import Cookies from 'js-cookie';

export const getGroupKeys = () => {
    const groupKeys = Cookies.get('groupKeys');
    return groupKeys ? JSON.parse(groupKeys) : [];
};

export const addGroupKey = (newGroupKey) => {
    const groupKeys = getGroupKeys();
    if (!groupKeys.includes(newGroupKey)) {
        groupKeys.push(newGroupKey);
        Cookies.set('groupKeys', JSON.stringify(groupKeys), { expires: 180 });
    }
};
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';

const CustomBarChart = ({ members, tempAmount, realAmount }) => {

    const data = members.map((member, index) => ({
        member,
        "建て替えた金額": tempAmount[index],
        "負担する金額": realAmount[index]
    }));

    return (
    <ResponsiveContainer width="100%" height={400}>
        <BarChart
        layout="vertical"
        width={600}
        height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
        <XAxis type="number" />
        <YAxis dataKey="member" type="category" />
        <Tooltip />
        <Legend formatter={(value) => {
            if (value === "建て替えた金額") return "建て替えた金額";
            if (value === "負担する金額") return "負担する金額";
            return value;
        }} />
        <Bar dataKey="建て替えた金額" fill="#d9534f">
            <LabelList dataKey="建て替えた金額" position="right" />
        </Bar>
        <Bar dataKey="負担する金額" fill="#5cb85c">
            <LabelList dataKey="負担する金額" position="right" />
        </Bar>
        </BarChart>
    </ResponsiveContainer>
    );
};
    

export default CustomBarChart;
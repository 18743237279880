import React from 'react';
import "./WaricanMemoryTitle.css"

const InputField = (props) => {
  const name = props.name
  return (
    <p className='waricanMemoryTitle'>
        {name}
    </p>
  );
};

export default InputField;
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import App from "./App";

Amplify.configure(config);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

//Amplyfy,configureを記載する


root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

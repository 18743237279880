import React, { useEffect } from 'react';
import "./IntroTitle.css"


const AnimationComponent = () => {

  return (
    <div class="loop-wrap">
      <ul class="loop-area">
        <li class="content">🍺</li>
        <li class="content">💗</li>
        <li class="content">🧳</li>
        <li class="content">🛒</li>
      </ul>
      <ul class="loop-area">
        <li class="content">🍻</li>
        <li class="content">😍</li>
        <li class="content">🏖️</li>
        <li class="content">🛍️</li>
      </ul>
    </div>
  );
};

export default AnimationComponent;
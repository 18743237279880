import React from 'react';
import "./RationSet.css"

const defaultProps = {
  label: '均等に分ける',
};

const Button = ({command, isSelected}) => {

  return (
    <button 
      className={`rationEvenButton ${isSelected ? 'selected' : ''}`} 
      onClick={command}>
      {defaultProps.label}
    </button>
  );
};

export default Button;
import React from 'react';
import "./HowToInfo.css"


const HowToInfo = (props) => {

  return (
    <>
    <ul className='horizontal-list'>
        <li className='scroll'>
            <div className='stepState'>
                <li class="stepItem1">STEP1画像</li>
            </div>
            <div className='stepExplainState'>
                <div>
                    <p className='stepFont'>STEP1</p>
                    <p className='stepExplain'>
                    グループを作成！<br/>
                    グループ名・メンバー・シーン・外貨を選択
                    </p>
                </div>
            </div>
        </li>
        <li className='scroll'>
            <div className='stepState'>
                <li class="stepItem1">STEP2画像</li>
            </div>
            <div className='stepExplainState'>
                <div>
                    <p className='stepFont'>STEP2</p>
                    <p className='stepExplain'>
                    グループを作成！<br/>
                    グループ名・メンバー・シーン・外貨を選択
                    </p>
                </div>
            </div>
        </li>
        <li className='scroll'>
            <div className='stepState'>
                <li class="stepItem1">STEP3画像</li>
            </div>
            <div className='stepExplainState'>
                <div>
                    <p className='stepFont'>STEP3</p>
                    <p className='stepExplain'>
                    グループを作成！<br/>
                    グループ名・メンバー・シーン・外貨を選択
                    </p>
                </div>
            </div>
        </li>
        <li className='scroll'>
            <div className='stepState'>
                <li class="stepItem1">STEP4画像</li>
            </div>
            <div className='stepExplainState'>
                <div>
                    <p className='stepFont'>STEP4</p>
                    <p className='stepExplain'>
                    グループを作成！<br/>
                    グループ名・メンバー・シーン・外貨を選択
                    </p>
                </div>
            </div>
        </li>
    </ul>
    </>
  );
};

export default HowToInfo;
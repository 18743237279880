import React, { useContext, useState } from "react";
import "./Selectedmember.css"
import InputRation from "../InputRation/InputRation"


const SelectMember = ({members, weights, details, splitMethod, commandSetWeights, commandSetDetails}) => {

const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (index) => {
    let currentWeights = [...weights];;
    const newSelectedButtons = [...selectedButtons];
    if (newSelectedButtons.includes(index)) {
      newSelectedButtons.splice(newSelectedButtons.indexOf(index), 1);
      currentWeights[index] = 0;
    } else {
      newSelectedButtons.push(index);
      currentWeights[index] = 1;
    }
    setSelectedButtons(newSelectedButtons);
    commandSetWeights(currentWeights);
  };

  const handleWeightChange = (index, value) => {
    let currentWeights = [...weights];
    if (currentWeights[index] !== 0) {
      currentWeights[index] = value;
      commandSetWeights(currentWeights);
    }
  }

  // 画面立ち上げと同時に、すべてのボタンが選択されている状態にする。
  useState(() => {
    setSelectedButtons([...Array(members.length).keys()]);
  }, []);

  return (
    <>
      <ul className="group-members">
        {members.map((item, index) => (
          <button
            key={index}
            className={`group-member2 ${selectedButtons.includes(index) ? 'selected' : ''}`}
            onClick={() => handleButtonClick(index)}
          >
            {item}
            {splitMethod === 'weighted' && 
              <InputRation 
                index={index} 
                value={weights[index]}
                command={handleWeightChange}/>}
            ￥
            {details[index]}
          </button>
        ))}
      </ul>
    </>
  );
};

export default SelectMember;